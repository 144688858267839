const ChevronRight = ({ size = 20, className = '' }: { size?: number; className?: string }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.75 10L7.5 16.25L6.625 15.375L12 10L6.625 4.625L7.5 3.75L13.75 10Z'
        fill='url(#paint0_linear_5890_1734)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_5890_1734'
          x1='10.1875'
          y1='3.75'
          x2='10.1875'
          y2='16.25'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='var(--primary-gradient-to)' />
          <stop offset='1' stopColor='var(--primary-gradient-from)' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ChevronRight;
