import { OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import UserprincipalService from '../../modules/UserPrinciple/userPrinicple';
import { useAppDispatch } from '../../store';
import {
  selectUserPrinciple,
  setOktaUser,
  setUserPrinciple,
} from '../../store/slices/main/mainSlice';
import oktaConfig from './oktaConfig';

const config = {
  issuer: oktaConfig[window.location.origin]?.baseUrl,
  clientId: oktaConfig[window.location.origin]?.clientId,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  post_logout_redirect_uri: window.location.origin + '/logout/callback',
};

const oktaAuth = new OktaAuth(config);
export default oktaAuth;

export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const dispatch = useAppDispatch();
  const userPrinicple = useSelector(selectUserPrinciple);

  const getCusomters = async () => {
    const response = await UserprincipalService.getPricnipleCustomers();
    if (response) dispatch(setUserPrinciple(response));
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      if (userPrinicple) {
        // If userPrinicple is already set, no need to fetch again
        return;
      }
      const email = authState?.idToken?.claims.email;
      const name = authState?.idToken?.claims.name;
      dispatch(setOktaUser({ email: email ?? '', name: name ?? '' }));
      getCusomters();
    }
  }, [authState]);

  if (process.env.NODE_ENV === 'development') {
    return <>{children}</>;
  }

  if (!authState) {
    // Still loading auth state
    return <div>Loading...</div>;
  }

  if (!authState.isAuthenticated) {
    // If user is not authenticated, redirect to login
    oktaAuth.signInWithRedirect();
    return <></>; // Return null while redirecting
  }
  // If user is authenticated, render the children components
  return <>{children}</>;
};


