import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';

export const ApprovalHistoryService = {
  getHistory: async (feature: string, recodrdId: string) => {
    try {
      const response = await apiClient.get(
        `/api/approval-management/approvals/${feature}/items/${recodrdId}/history`,
      );
      return handleApiResponse<ApprovalHistory[]>(response);
    } catch (error) {
      return 'Error';
    }
  },
};
