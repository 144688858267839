import { SortOrder } from 'primereact/datatable';
import getCountryISO2 from '../../components/CountrySelect/isoncCodeMapping';
import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';

const ROOT = '/api/pricing-management/';

const CUSTOMER_MANAGEMENT = '/api/customer-management';
const ORDER_MANAGEMENT = '/api/order-management';
const BENEFICIARY_MANAGEMENT = '/api/beneficiary-management';

const PaymentService = {
  async getRateRequests(
    page = 0,
    rows = 0,
    payload: { [key: string]: string[] | string },
    sortField = '',
    sortOrder: SortOrder = null,
  ): Promise<RateRequestResponse | null> {
    try {
      const response = await apiClient.post(
        `${ORDER_MANAGEMENT}/orders/customers?page=${page}&size=${rows}&sortBy=${sortField}&direction=${sortOrder === 1 ? 'asc' : 'desc'}`,
        payload,
      );
      return handleApiResponse(response);
    } catch (error) {
      console.error('Error fetching payments:', error);
      return null;
    }
  },

  async getQuickQuote(data: any): Promise<QuickQouteProps | 'Error'> {
    try {
      const response = await apiClient.post(`${ROOT}quick-quote`, data);
      return handleApiResponse<QuickQouteProps>(response);
    } catch (error) {
      return 'Error';
    }
  },

  async getQuoteByMargin(data: any): Promise<QuickQouteProps> {
    try {
      const response = await apiClient.post(`${ROOT}quotes`, data);
      return handleApiResponse<QuickQouteProps>(response);
    } catch (error) {
      console.error('Error creating quote', error);
      throw error;
    }
  },

  async recalculateQuote(data: any) {
    const response = await apiClient.post(`${ROOT}quotes/recalculate`, data);
    return handleApiResponse<QuickQouteProps>(response);
  },

  async reQuote(quoteId: string) {
    const response = await apiClient.post(`${ROOT}quotes/requote`, { quoteId });
    return handleApiResponse<QuickQouteProps>(response);
  },

  async getQuickQuoteById(quoteId: string) {
    const response = await apiClient.get(`${ROOT}quotes/${quoteId}`);
    return handleApiResponse<{
      appliedPricing: Array<AppliedPricing>;
      quote: QuickQouteProps;
    }>(response);
  },

  async createNewCustomer(payload: CustomerCreationForm) {
    return apiClient.post(`${CUSTOMER_MANAGEMENT}/customers-details`, payload);
  },

  async updateRemitter(payload: CreatePaymentPayload) {
    const response = await apiClient.post(`${ORDER_MANAGEMENT}/orders/create-remitter`, payload);
    return handleApiResponse(response);
  },

  async getBeneficiaryFormByCountry(countryCode: string, buyCurrency: string) {
    const response = await apiClient.get(
      `${BENEFICIARY_MANAGEMENT}/payment-validation-rules?bankCountryIsoCode=${countryCode}&buyCurrency=${buyCurrency}`,
    );
    return handleApiResponse<Array<BeneficiaryType>>(response);
  },

  async getBICCode(countryCode: string, bicCode: string, city: string, bankName: string) {
    const response = await apiClient.post(`${BENEFICIARY_MANAGEMENT}/bic-code`, {
      bankCountryIsoCode: countryCode,
      bankAddressCity: city,
      institutionName: bankName,
      bic: bicCode,
    });
    return handleApiResponse<Array<Record<string, string>>>(response);
  },

  async getBankDirectory(countryCode: string, bicCode: string, city: string, bankName: string) {
    const response = await apiClient.post(`${BENEFICIARY_MANAGEMENT}/bank-directory`, {
      bankCountryIsoCode: countryCode,
      bankAddressCity: city,
      institutionName: bankName,
      nationalId: bicCode,
    });
    return handleApiResponse<Array<Record<string, string>>>(response);
  },

  async addPaymentCreditor(
    quoteId: string,
    paymentId: number,
    routingCodeType: 'BIC' | 'FW',
    formData: any,
    routingCodeData: any,
  ) {
    const requestData: any = {
      quoteId,
      paymentId,
      beneAddress: {
        addressType: 'BENE_ADDRESS',
        street1: formData.BeneficiaryAddressStreet,
        city: formData.BeneficiaryAddressCity,
        state: formData.BeneficiaryAddressRegion,
        country: formData.BeneficiaryAddressCountry
          ? getCountryISO2(formData.BeneficiaryAddressCountry?.code)
          : undefined,
      },
      accountName: formData.AccountName,
      bankName: formData.BeneficiaryBankName,
      accountNumber: formData.AccountNumber,
      routingCode: formData.BankCode,
      routingCodeType,
      bankAddress: {
        addressType: 'BANK_ADDRESS',
        street1: formData.BeneficiaryBankAddressStreet,
        city: formData.BeneficiaryBankAddressCity,
        state: formData.BeneficiaryAddressRegion,
        postCode:
          routingCodeData['credit'][`${routingCodeType === 'BIC' ? 'ReZipCode' : 'ReZipCode'}`],
        country:
          routingCodeData['credit'][
            `${routingCodeType === 'BIC' ? 'IsoCountryCode' : 'IsoCountryCode'}`
          ],
      },
      paymentPurpose: formData.paymentPurpose,
    };

    if (formData.BeneficiaryAddressCountry) {
      requestData.countryOfResidence = getCountryISO2(formData.BeneficiaryAddressCountry.code);
    }

    if (formData.IntermediaryBankCode) {
      requestData.intermediaryBankName = formData.IntermediaryBankName;
      requestData.intermediaryBankCode = formData.IntermediaryBankCode;
      requestData.intermediaryBankCodeType = routingCodeType;
      requestData.intermediaryBankAdd = {
        addressType: 'INTERMEDIARY_BANK_ADDRESS',
        street1: formData.IntermediaryBankAddressStreet,
        city: formData.IntermediaryBankAddressCity,
        state: formData.IntermediaryAddressRegion,
        postCode:
          routingCodeData['credit'][`${routingCodeType === 'BIC' ? 'ReZipCode' : 'ReZipCode'}`],
        country:
          routingCodeData['intermediary']?.[
            `${routingCodeType === 'BIC' ? 'IsoCountryCode' : 'IsoCountryCode'}`
          ],
      };
    }

    const response = await apiClient.post(
      `${BENEFICIARY_MANAGEMENT}/payment-creditors`,
      requestData,
    );
    return handleApiResponse<{ orderId: number }>(response);
  },

  async getInterMediariesData(isoCode: string, bicCode: string) {
    const response = await apiClient.get(
      `${BENEFICIARY_MANAGEMENT}/intermediaries/${isoCode}/${bicCode}`,
    );
    return handleApiResponse<
      Array<{
        intermediary: Array<{
          bankcodetype: string;
          bankcode: string;
          localcode: string;
          bankname: string;
          biccode: string;
          address: Array<string>;
        }>;
      }>
    >(response);
  },

  async verifyIbanExists(iban: string) {
    const response = await apiClient.get(`${BENEFICIARY_MANAGEMENT}/iban-to-bic/${iban}`);
    return handleApiResponse<{
      id: string;
      countryName: string;
      ibanBic: string;
      ibanIsoCountryCode: string;
      ibanNationalId: string;
      institutionName: string;
      isoCountryCode: string;
      modificationFlag: string;
      recordKey: string;
      routingBic: string;
      serviceContext: string;
      status: string;
    }>(response);
  },

  async getClientById(id: string | number): Promise<Client | null> {
    try {
      const response = await apiClient.get(`${CUSTOMER_MANAGEMENT}/customers/${id}`);
      return handleApiResponse<Client | null>(response);
    } catch (error) {
      console.error(`Error fetching client with id ${id}:`, error);
      return null;
    }
  },

  async initiateOrderApproval(orderId: number, customerOptIn: boolean, requesterOptIn: boolean) {
    const response = await apiClient.post(`${ORDER_MANAGEMENT}/initiate-approval/${orderId}`, {
      paymentConfirmationConsumerOptIn: customerOptIn,
      paymentConfirmationRequesterOptIn: requesterOptIn,
    });
    return handleApiResponse<any>(response);
  },

  async downloadDisclosureStatement(orderId: number, quoteId: string) {
    const response = await apiClient.get(
      `${ORDER_MANAGEMENT}/orders/${orderId}/quote/${quoteId}/disclosure`,
      {
        responseType: 'blob',
      },
    );
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'disclosure_statement.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  },

  async getOrderById(orderId: number) {
    const response = await apiClient.get(`${ORDER_MANAGEMENT}/orders/${orderId}`);
    return handleApiResponse<Order>(response);
  },

  approveOrRject(orderId: number, requestBody: any) {
    return apiClient.post(`${ORDER_MANAGEMENT}/orders/${orderId}/approve`, requestBody);
  },

  saveQuote(quoteId: string) {
    return apiClient.post(`${ORDER_MANAGEMENT}/orders/${quoteId}`, {});
  },
};

export default PaymentService;
