import { ChevronRight, Money, User, UserAvatar, UserIdentification } from '@carbon/icons-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PageHead } from '../../components';
import { Translate } from '../../i18n/translate';
import { NextFilled } from '../../icons';
import DashBoardService from './DashboardService';

const PendingTaskWidget = () => {
  const [pendingItems, setPendingItems] = useState<PendingItemsType>({
    PAYMENT: { totalCount: 0 },
    MANAGE_CUSTOMER: { totalCount: 0 },
    MANAGE_APPROVAL_WORKFLOW: { totalCount: 0 },
    MANAGE_USER: { totalCount: 0 },
  });

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsloading(true);
    DashBoardService.getPendingDetails()
      .then((res) => {
        if (!res && res.data) return;
        const data = res.data;
        if (data.result?.success) {
          const items = data?.data.pendingRecordsInfoMap;
          setPendingItems(items);
        }
      })
      .finally(() => {
        setIsloading(false);
      });
  }, []);

  const options = [
    {
      name: 'payments',
      icon: <Money size={16} />,
      key: 'PAYMENT',
      link: '/payment/rate-requests',
    },
    {
      name: 'approval_templates',
      icon: <UserIdentification size={16} />,
      key: 'MANAGE_APPROVAL_WORKFLOW',
      link: '/manage/approvals',
    },
    {
      name: 'users',
      icon: <User size={16} />,
      key: 'MANAGE_USER',
      link: '/manage/user',
    },
    {
      name: 'customers',
      icon: <UserAvatar size={16} />,
      key: 'MANAGE_CUSTOMER',
      link: '/client',
    },
  ];

  const tiles = [
    {
      name: 'pending_approval',
    },
  ];

  return (
    <div className='card p-0'>
      <PageHead
        title='pending_request_header'
        description='pending_request_subtitle'
        className='pt-8 px-8'
      />
      <div className='py-2 px-8 flex gap-3 items-stretch overflow-x-hidden hover:overflow-x-auto'>
        {options.map((option, index) => (
          <div
            key={option.name}
            onClick={() => {
              setSelectedOptionIndex(index);
            }}
            className={`flex flex-shrink-0 items-stretch cursor-pointer ${selectedOptionIndex === index ? 'border-2 border-transparent bg-gradient-to-b to-primary-gradient-from from-primary-gradient-to bg-origin-border' : 'border border-neutral-border'} rounded-[12px] md:rounded-[20px]`}
          >
            <div
              className={`py-2 px-4 flex flex-col md:flex-row gap-1.5 ${selectedOptionIndex === index ? 'text-selected bg-primary-surface-light' : 'text-neutral-4 bg-neutral-surface-subtle'} rounded-[12px] md:rounded-[20px] items-center`}
            >
              {option.icon}
              <Translate value={option.name} className='text-xs-medium' />
            </div>
          </div>
        ))}
      </div>
      {isLoading ? (
        <div className='text-center mt-4'>
          <Translate value='loading' />
        </div>
      ) : (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 lg:mx-2 pb-8 px-8'>
          {tiles.map((tile) => (
            <div
              className='card p-3 md:p-[16px] cursor-pointer flex justify-between gap-4'
              key={tile.name}
              onClick={() => {
                navigate(options[selectedOptionIndex].link);
              }}
            >
              <div className='flex md:flex-col md:gap-2 items-center md:items-start flex-grow justify-between'>
                <div className='text-neutral-3 text-sm-medium'>
                  <Translate value={tile.name} className='' />
                </div>
                <div className='text-2xl-bold'>
                  {pendingItems[options[selectedOptionIndex].key as keyof PendingItemsType]
                    ?.totalCount ?? 0}
                </div>
              </div>
              <div className='flex items-center md:items-start'>
                <ChevronRight size={20} />
              </div>
            </div>
          ))}
          <div className='card p-4 md:p-[16px] cursor-pointer flex gap-2 md:flex-col items-center justify-center'>
            <NextFilled />
            <div>
              <Translate
                value='go_to_action_centre'
                className='bg-gradient-to-b from-primary-gradient-from to-primary-gradient-to bg-clip-text text-transparent text-sm-semibold'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingTaskWidget;
