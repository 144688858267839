import { Search } from '../../icons';
import TextInput from '../TextInput';

const TableSearch = ({
  globalFilterValue,
  onGlobalFilterChange,
}: {
  globalFilterValue: any;
  onGlobalFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <TextInput
      className='text-sm-regular h-12 global-search'
      value={globalFilterValue}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onGlobalFilterChange(e)}
      placeholder='Search'
      iconPosition='left'
      searchIcon={<Search />}
      iconClassName='mt-[-8px]'
    />
  );
};

export default TableSearch;
