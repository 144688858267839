import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import SearchDropDown from '../../../../../../components/SearchDropdown';
import { Translate } from '../../../../../../i18n/translate';
import { selectCurrentCustomer } from '../../../../../../store/slices/main/mainSlice';
import { useSelector } from 'react-redux';

type Props = {
  feature: FeatureType;
  isEditMode: boolean;
  initialReqAppCount: number;
  approverCategory: 'USER' | 'ROLE';
  approvers: Array<ApproverType>;
  updateDetails: (approvers: Array<ApproverType>, reqAppCount: number) => void;
};

const SelectApproverForm = ({
  feature,
  isEditMode,
  initialReqAppCount,
  approverCategory,
  approvers,
  updateDetails,
}: Props) => {
  const [selectedItems, setSelectedItems] = useState(approvers);
  const [reqAppCount, setReqAppCount] = useState(initialReqAppCount);

  const queryClient = useQueryClient();

  const selectedCustomer = useSelector(selectCurrentCustomer);

  const availableApprovers = queryClient.getQueryData<(User | Role)[]>([
    approverCategory === 'USER' ? 'getUsersByFeature' : 'getRolesByFeature',
    feature,
    selectedCustomer?.id,
  ]);

  return (
    <div className='h-full flex flex-col gap-4 overflow-hidden pb-6'>
      <div className='flex pt-4 px-1 justify-between items-center'>
        <Translate
          value={
            approverCategory === 'USER'
              ? 'create_approval.set_approver_number'
              : 'create_approval.set_approver_number_roles'
          }
          className='text-neutral-1 text-sm-semibold'
        />
        <div className='grid grid-cols-3 w-32 h-10 rounded-md border border-neutral-5 shadow-input'>
          <button
            onClick={() => setReqAppCount(Math.max(reqAppCount - 1, 1))}
            className='border-r border-neutral-5 text-neutral-1'
          >
            -
          </button>
          <div className='flex justify-center items-center text-neutral-1 text-sm-semibold'>
            {reqAppCount}
          </div>
          <button
            onClick={() => setReqAppCount(reqAppCount + 1)}
            className='border-l border-neutral-5 text-neutral-1'
          >
            +
          </button>
        </div>
      </div>
      {!availableApprovers || availableApprovers.length === 0 ? (
        <div>No data</div>
      ) : (
        <div className='flex-grow grid grid-rows-[aut_1fr] gap-4 h-full overflow-hidden'>
          <SearchDropDown
            items={
              approverCategory === 'USER'
                ? (availableApprovers as User[]).map((item) => ({
                    id: item.id as number,
                    label: item.userIdentity.firstName,
                    subLabel: item.userIdentity.email,
                  }))
                : (availableApprovers as Role[]).map((item) => ({
                    id: item.id,
                    label: item.roleName,
                    subLabel: `${item.numberOfUsers || 0} users`,
                  }))
            }
            emptyMessage='no_results'
            selectedItems={selectedItems.map((item) =>
              approverCategory === 'USER' ? (item.userId as number) : (item.roleId as number),
            )}
            onSelectChange={(ids) =>
              setSelectedItems(
                ids.map((id) =>
                  approverCategory === 'USER'
                    ? { userId: id as number, roleId: null, userName: null }
                    : { roleId: id as number, userId: null, userName: null },
                ),
              )
            }
            disabled={!isEditMode}
          />
        </div>
      )}
      {isEditMode ? (
        <div className='flex justify-end gap-2'>
          <Button severity='secondary' onClick={() => updateDetails([], reqAppCount)}>
            <Translate value='clear_all' />
          </Button>
          <Button
            severity='info'
            disabled={!availableApprovers || availableApprovers.length === 0}
            onClick={() => updateDetails(selectedItems, reqAppCount)}
          >
            <Translate value='save_selection' />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default SelectApproverForm;
