import { Checkmark, Warning } from '@carbon/icons-react';

interface Step {
  id: string;
  content: React.ReactNode;
}

interface CustomStepperProps {
  steps: Step[];
  activeStep?: string;
  onStepClick?: (step: string) => void;
  visited: Set<string>;
  validations: Set<string>;
}

const CustomStepper: React.FC<CustomStepperProps> = ({
  steps,
  onStepClick,
  visited,
  validations,
}) => {
  return (
    <div className='relative'>
      {steps.map((step, index) => (
        <div key={index} className='flex items-start mb-1 relative'>
          <div>
            <div
              className={`absolute top-0 left-[0.929rem] h-full ${
                visited.has(step.id) ? 'bg-primary w-[0.214rem]' : 'bg-lighGray w-[0.143rem]'
              }`}
            />

            {/* Step indicator */}
            <div
              className={`relative w-8 h-8 flex items-center justify-center rounded-full ${onStepClick ? 'cursor-pointer' : ''} ${
                validations.has(step.id)
                  ? 'bg-error-1'
                  : visited.has(step.id)
                    ? 'bg-primary'
                    : 'bg-gray-300'
              }`}
              onClick={() => {
                if (onStepClick) {
                  onStepClick(step.id);
                }
              }}
            >
              {validations.has(step.id) ? (
                <Warning color='white' size={18} />
              ) : visited.has(step.id) ? (
                <Checkmark color='white' size={18} />
              ) : (
                <img src='/images/stepper-inactive.svg' />
              )}
            </div>
          </div>
          <div className='ml-4 flex-1 px-5 pb-5 bg-red'>{step.content}</div>
        </div>
      ))}
    </div>
  );
};

export default CustomStepper;
