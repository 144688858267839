import { CustomTabView } from '../../../components';
import { translateWithValues } from '../../../i18n/translate';
import Table from './Table';

const Dashboard = () => {
  const tabs = [
    {
      header: translateWithValues('all_notifications'),
      content: <Table type="ALL" />,
    },
    {
      header: translateWithValues('unread'),
      content: <Table type="UNREAD" />,
    },
    {
      header: translateWithValues('deleted'),
      content: <Table type="DELETED" />,
    },
  ];
  return (
    <div className='dashboard-padding'>
      <CustomTabView tabs={tabs} initialIndex={0} />
    </div>
  );
};

export default Dashboard;
