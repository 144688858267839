import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import store from '../store';
import { sendToast } from '../store/slices/main/mainSlice';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const enumToCamelCase = (value: string) => {
  if (!value) {
    return;
  }
  return value
    .split('_')
    .map((i) => i.charAt(0).toUpperCase() + i.slice(1).toLocaleLowerCase())
    .join(' ');
};

export function handleApiResponse<T>(response: any, field = ''): T {
  const { status } = response;
  if (status == 500) {
    store.dispatch(sendToast({ summary: 'error', detail: 'generic.api.error', severity: 'error' }));
    throw new Error('');
  } else {
    if (response.data) {
      if (response.data?.result.success) {
        if (field) {
          return response.data?.data[field] as T;
        }
        return response.data?.data as T;
      }
      throw new Error(
        (response.data?.result.errors &&
          response.data?.result.errors[0] &&
          response.data?.result.errors[0].errorTextCode) ??
          'API request failed',
      );
    }
  }
  throw new Error(response?.response?.data?.error ?? 'API request failed');
}

export const updateColorVariables = (colorConfig: ColorConfig[], colors: ColorsFormData) => {
  colorConfig.forEach(({ name, var: cssVar }) => {
    let colorValue = colors[name];
    if (colorValue) {
      if (!colorValue.startsWith('#')) {
        colorValue = '#' + colorValue;
      }
      document.documentElement.style.setProperty(cssVar as string, colorValue);
    }
  });
};

export const formatDate = (dateString: string) => {
  const d = new Date(dateString);
  const day = String(d.getDate()).padStart(2, '0');
  const month = d.toLocaleString('default', { month: 'short' });
  const year = d.getFullYear();

  return `${day} ${month} ${year}`;
};

export const convertDateFormat = (dateString: string) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const convertToTimeFormat = (dateString: string) => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12;
  const formattedTime = `${formattedHours}:${minutes} ${ampm}`;
  return formattedTime;
};

export const isToday = (dateString: string) => {
  const givenDate = new Date(dateString);
  const today = new Date();
  return (
    givenDate.getFullYear() === today.getFullYear() &&
    givenDate.getMonth() === today.getMonth() &&
    givenDate.getDate() === today.getDate()
  );
};

export const formatDateRelativeTime = (dateString: string) => {
  const trimmedDateTimeString = dateString.split('.')[0] + 'Z';
  const date = new Date(trimmedDateTimeString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 60) {
    return 'Just now';
  }

  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  }

  if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  }

  if (diffInDays === 1) {
    return `Yesterday at ${date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}`;
  }

  if (diffInDays < 365) {
    // Display month and day if it's in the same year
    return date.toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  // If it's in a different year, display full date with year
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export function createRegexFromString(inputString: string) {
  // Escape special regex characters
  const specialChars = /[.*+?^${}()|[\]\\]/g; // Matches any special regex characters
  const escapedString = inputString.replace(specialChars, '\\$&'); // Escape them

  // Make sure the hyphen is treated as a literal
  const safeString = escapedString.replace(/\\-/, '-'); // Remove escaping for the hyphen
  const regexPattern = `^[${safeString}]+$`; // Use + for one or more occurrences
  return new RegExp(regexPattern);
}
