import Dashboard from './Dashboard';

const notificationRoutes = [
  {
    id: 'notificationDashboard',
    index: true,
    handle: {
      breadcrumb: '',
    },
    element: <Dashboard />,
  },
];

export default notificationRoutes;
