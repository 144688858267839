import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect } from 'react';
import { Status as StatusComp } from '../../../../../components';
import { Translate } from '../../../../../i18n/translate';
import { Status } from '../../../../../types';
import { formatDate } from '../../../../../utils/helper';

const Table = ({ roles, datesAssigned }: { roles: any; datesAssigned: any }) => {
  const renderDateAssigned = (role: any) => {
    return (
      <span className='text-sm-medium text-neutral-2'>
        {datesAssigned[role.id] ? formatDate(datesAssigned[role.id]) : 'N/A'}
      </span>
    );
  };

  useEffect(() => {
    console.log('roles', roles, datesAssigned);
  }, [datesAssigned, roles]);

  return (
    <div className='table-card'>
      <DataTable value={roles} size='large' dataKey='id' className='custom-table'>
        <Column
          field='role'
          header='Role'
          className='role-column'
          body={(role: any) => (
            <Translate value={role.roleName} className='text-neutral-3 text-sm-regular' />
          )}
        ></Column>
        <Column
          field='dateAssigned'
          header='Date assigned'
          body={renderDateAssigned}
          className='date-column'
        ></Column>
        <Column
          sortable
          field='status'
          header='Status'
          body={(role: Role) => (
            <StatusComp status={role.isActive ? Status.ACTIVE : Status.INACTIVE} />
          )}
        ></Column>
      </DataTable>
    </div>
  );
};

export default Table;
