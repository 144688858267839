import { ChevronRight, Close } from '@carbon/icons-react';
import React from 'react';
import { useNavigate } from 'react-router';
import { Translate } from '../../i18n/translate';

type Action = {
  icon: React.ReactNode;
  label: string;
  link: string;
};

type MoreActionsProps = {
  title: string;
  actions: Action[];
  onClose: () => void;
};

const MoreActions: React.FC<MoreActionsProps> = ({ title, actions, onClose }) => {
  const navigate = useNavigate();
  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div className='bg-white rounded-tr-lg rounded-tl-lg w-11/12 max-w-md p-4 shadow-lg absolute bottom-0'>
        <div className='flex justify-between items-center mb-4'>
          <h3 className='text-mobile-header-bold'>
            <Translate value={title} />
          </h3>
          <button className='text-gray-500 hover:text-gray-700 text-xl font-bold' onClick={onClose}>
            <Close size={20} />
          </button>
        </div>
        <ul className='divide-y divide-neutral-border'>
          {actions.map((action, index) => (
            <li
              key={index}
              className='flex justify-between items-center py-3 px-4 cursor-pointer hover:bg-gray-100'
              onClick={() => {
                navigate(action.link);
                onClose();
              }}
            >
              <div className='flex items-center space-x-3'>
                <div className='text-neutral-3'>{action.icon}</div>
                <span className='text-neutral-2 text-md-semibold'>
                  <Translate value={action.label} />
                </span>
              </div>
              <div>
                <ChevronRight size={20} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MoreActions;
