import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';

const ROOT = '/api/platform-notification-management';

const NotificationService = {
  async getNotifications(type = 'ALL', page = 0, rows = 10): Promise<NotificationResponse | null> {
    try {
      const response = await apiClient.get(
        `${ROOT}/notifications/${type}?page=${page}&size=${rows}&sortBy=createdAt&direction=desc`,
        {
          headers: { skipLoader: true },
        },
      );
      return handleApiResponse<NotificationResponse>(response);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      return null;
    }
  },

  async deleteNotification(payload: { notificationIds: number[] }) {
    try {
      const response = await apiClient.post(`${ROOT}/notifications/mark-deleted`, payload);
      return response;
    } catch (error) {
      console.error('Error deleting notification');
      return null;
    }
  },

  async readNotification(payload: { notificationIds: number[] }) {
    try {
      const response = await apiClient.post(`${ROOT}/notifications/mark-read`, payload);
      return response;
    } catch (error) {
      console.error('Error marking notifications as read');
      return null;
    }
  },

  async markAllAsRead() {
    try {
      const response = await apiClient.put(`${ROOT}/notifications/mark-read`);
      return response;
    } catch (error) {
      console.error('Error marking notifications as read');
      return null;
    }
  },
};

export default NotificationService;
