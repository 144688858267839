import { FormattedNumber } from 'react-intl';

interface FormatAmountProps {
  number: number;
  maximumFractionDigits?: number;
}

const FormatAmount = ({ number, maximumFractionDigits }: FormatAmountProps) => {
  return (
    <FormattedNumber
      value={number}
      style='decimal'
      maximumFractionDigits={maximumFractionDigits}
      minimumFractionDigits={maximumFractionDigits}
      useGrouping={true}
    />
  );
};

export default FormatAmount;
