import { PAYMENT_STATUS } from '../../types';

const PAYMENT_STATUS_OPTIONS = [
  {
    label: 'Created',
    value: PAYMENT_STATUS.CREATED,
  },
  {
    label: 'Pending',
    value: PAYMENT_STATUS.PENDING,
  },
  {
    label: 'Approval',
    value: PAYMENT_STATUS.APPROVAL,
  },
  {
    label: 'Committed',
    value: PAYMENT_STATUS.COMMITTED,
  },
  {
    label: 'Rejected',
    value: PAYMENT_STATUS.REJECTED,
  },
  {
    label: 'Deleted',
    value: PAYMENT_STATUS.DELETED,
  },
  {
    label: 'Compliance Failed',
    value: PAYMENT_STATUS.COMPLIANCE_FAILED,
  },
  {
    label: 'Pending Compliance',
    value: PAYMENT_STATUS.PENDING_COMPLIANCE,
  },
  {
    label: 'Abandoned',
    value: PAYMENT_STATUS.ABANDONED,
  },
];

export { PAYMENT_STATUS_OPTIONS };
