import { UserAvatar } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import React from 'react';
import { Translate } from '../../i18n/translate';
import './index.css';

interface CardProps {
  title: string;
  description: string;
  selected: boolean;
  disabled?: boolean;
  onSelect: () => void;
  handleOpenModal: () => void;
}

const RoleCard: React.FC<CardProps> = ({
  title,
  description,
  selected,
  disabled = false,
  onSelect,
  handleOpenModal,
}) => {
  return (
    <div
      className={`card w-[250px] flex flex-col ${selected ? 'border-primary bg-blue-50' : 'border-gray-200 bg-white'}`}
    >
      <div className='flex items-center justify-between gap-2'>
        <div className='flex items-center gap-2'>
          <UserAvatar size={24} className='!w-[24px]' />
          <h3 className='text-[18px] font-bold text-neutral-1 max-w-[164px] break-words'>
            <Translate value={title} />
          </h3>
        </div>

        <div
          className={`rounded-full ${
            selected ? 'bg-primary' : 'bg-gray-200'
          } flex items-center justify-center`}
        >
          <Checkbox checked={selected} onClick={onSelect} disabled={disabled} />
        </div>
      </div>
      <p className='text-neutral-6 mt-2 grow'>
        <Translate value={description} />
      </p>
      <div className='mt-1 text-center font-semibold'>
        <Button
          className='!text-transparent bg-gradient-to-b from-primary-gradient-from to-primary-gradient-to bg-clip-text text-transparent'
          onClick={handleOpenModal}
        >
          <Translate value='view_permissions' />
        </Button>
      </div>
    </div>
  );
};

export default RoleCard;
