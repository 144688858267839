import { TranslateWithValues } from '../../i18n/translate';

const PageHead = ({
  title,
  description,
  main = true,
  button,
  actionUI,
  className,
  params,
}: PageHeadProps) => {
  return (
    <div className={`${button ? 'flex justify-between' : ''} ${className}`}>
      <div className={`${main ? 'text-xs-semibold' : 'text-xs-bold'}`}>
        <TranslateWithValues value={title} params={params} className='text-neutral-1' />
        {description && (
          <div className='text-md-regular mt-1 text-neutral-3'>
            <TranslateWithValues value={description} params={params} />
          </div>
        )}
      </div>
      {button && button}
      {actionUI && <div className='mt-[9px]'>{actionUI}</div>}
    </div>
  );
};

export default PageHead;
