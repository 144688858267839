import { RoleGuard } from '../../../components';
import { AddOrEditOrView } from '../../../types';
import paymentRole from '../paymentRole';
import Dashboard from './Dashboard';
import QuickQuoteSummary from './QuickQuoteSummary';
import RequestQuickQuote from './RequestQuickQuote';

const rateRequestRoutes = [
  {
    id: 'rateRequestDashboard',
    path: '',
    index: true,
    element: (
      <RoleGuard roles={paymentRole.viewPayments}>
        <Dashboard />
      </RoleGuard>
    ),
  },
  {
    id: 'add',
    path: 'add',
    handle: {
      breadcrumb: 'New Rate Request',
    },
    element: (
      <RoleGuard roles={paymentRole.viewPayments}>
        <RequestQuickQuote mode={AddOrEditOrView.Add} />
      </RoleGuard>
    ),
  },
  {
    id: 'rateRequest-edit',
    path: 'edit/:quoteId',
    element: (
      <RoleGuard roles={paymentRole.editPayments}>
        <RequestQuickQuote mode={AddOrEditOrView.Edit} />
      </RoleGuard>
    ),
  },
  {
    id: 'rateRequest-summary',
    path: 'summary',
    handle: {
      breadcrumb: 'Summary',
    },
    element: (
      <RoleGuard roles={paymentRole.viewPayments}>
        <QuickQuoteSummary mode={AddOrEditOrView.Add} />
      </RoleGuard>
    ),
  },
  {
    id: 'rateRequest-view',
    path: 'view',
    handle: {
      breadcrumb: 'View',
    },
    element: (
      <RoleGuard roles={paymentRole.viewPayments}>
        <QuickQuoteSummary mode={AddOrEditOrView.View} />
      </RoleGuard>
    ),
  },
  {
    id: 'rateRequest-approve',
    path: 'approve',
    handle: {
      breadcrumb: 'Approve',
    },
    element: (
      <RoleGuard roles={paymentRole.viewPayments}>
        <QuickQuoteSummary mode={AddOrEditOrView.Approve} />
      </RoleGuard>
    ),
  },
];

export default rateRequestRoutes;
