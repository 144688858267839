import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { translateWithValues } from '../../i18n/translate';
import ErrorIcon from '../ErrorIcon';

export interface TextInputProps {
  id?: string;
  label?: string;
  errorLabel?: string;
  placeholder?: string;
  ariaDescribedBy?: string;
  isRequired?: boolean;
  value?: string;
  name?: string;
  maxLength?: number;
  disabled?: boolean | string;
  formRegister?: UseFormRegisterReturn<string>;
  [key: string]: any;
  onChange?: (e: any) => void;
  className?: string;
  searchIcon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  iconClassName?: string;
  type?: 'text' | 'password' | 'email' | 'number';
  error?: any;
  onKeyDown?: (e: any) => void;
  onBlurCapture?: (e: any) => void;
}

const TextInput = ({
  id,
  label,
  errorLabel,
  placeholder,
  disabled,
  ariaDescribedBy,
  isRequired,
  value,
  name,
  maxLength,
  formRegister,
  error,
  className,
  iconPosition = 'right',
  searchIcon,
  iconClassName,
  type = 'text',
  onChange,
  onKeyDown,
  onBlurCapture,
}: TextInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const errorMessage =
    error &&
    (error.type === 'required'
      ? `${translateWithValues(label || errorLabel)} ${translateWithValues('is_required')}`
      : translateWithValues(error.message));

  return (
    <div className={`${className} flex flex-col gap-1`}>
      {label && (
        <label
          htmlFor={id}
          className='whitespace-nowrap overflow-hidden text-ellipsis inline-block'
        >
          {translateWithValues(label)} {isRequired ? <span className='text-error-1'>*</span> : ''}
        </label>
      )}
      <IconField iconPosition={iconPosition}>
        <InputText
          type={type === 'password' && !isPasswordVisible ? 'password' : 'text'}
          tooltip={error && errorMessage}
          tooltipOptions={{ position: 'bottom' }}
          className={`w-full ${searchIcon && iconPosition === 'left' ? 'pl-8' : ''}`}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlurCapture={onBlurCapture}
          id={id}
          name={name}
          maxLength={maxLength}
          value={value}
          disabled={!!disabled}
          aria-describedby={ariaDescribedBy}
          placeholder={placeholder && translateWithValues(placeholder)}
          invalid={!!error}
          {...formRegister}
        />
        {type === 'password' && (
          <InputIcon>
            <button
              type='button'
              onClick={togglePasswordVisibility}
              className='focus:outline-none'
              aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
            >
              {isPasswordVisible ? '👁️' : '👁️‍🗨️'}
            </button>
          </InputIcon>
        )}
        {searchIcon && <InputIcon className={iconClassName}>{searchIcon}</InputIcon>}
        {!!error && (
          <InputIcon>
            <ErrorIcon isFilled={false} size={16} withBackground={false} />
          </InputIcon>
        )}
      </IconField>
    </div>
  );
};

export default TextInput;
