import { RoleGuard } from '../../components';
import paymentRole from './paymentRole';
import RateRequestOutlet from './RateRequest';
import rateRequestRoutes from './RateRequest/routes';

const paymentRoutes = [
  {
    id: 'rate-requests',
    path: 'rate-requests',
    handle: {
      breadcrumb: 'Rate Requests',
    },
    element: (
      <RoleGuard roles={paymentRole.viewPayments}>
        <RateRequestOutlet />
      </RoleGuard>
    ),
    children: rateRequestRoutes,
  },
];

export default paymentRoutes;
