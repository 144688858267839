import { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';
import { PageHeadContext, PageHeadProvider } from '../../../providers/PageHead';

const ApprovalsOutlet = () => {
  const context = useContext(PageHeadContext);
  const location = useLocation();
  const { setPageHeadData } = context;

  useEffect(() => {
    if (
      !location.pathname.includes('view-strucutre') &&
      !location.pathname.includes('update-status')
    ) {
      setPageHeadData({
        title: 'approvals',
        description: 'approvals.description',
      });
    } else {
      setPageHeadData({
        title: '',
        description: '',
      });
    }
    return () => {
      setPageHeadData({
        title: '',
        description: '',
      });
    };
  }, [location]);

  return (
    <>
      <PageHeadProvider>
        <div className='dashboard-padding'>
          <Outlet />
        </div>
      </PageHeadProvider>
    </>
  );
};
export default ApprovalsOutlet;
