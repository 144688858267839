import { useNavigate } from 'react-router';
import { Button } from 'primereact/button';
import { Translate } from '../../../../../i18n/translate';
import DottedConnector from './Step2/DottedConnector';
import LevelCard from './Step2/LevelCard';

type Props = {
  formData: {
    owningCustomerId: number | undefined;
    modelName: string;
    appStructure: AppStructureType;
    feature: FeatureType;
    approverCategory: 'USER' | 'ROLE';
    appModelConfigs: Array<AppModelConfig>;
  };
  updateDetails?: (appModelConfigs: Array<AppModelConfig>) => void;
  submitStep: () => void;
};

function Step3({ formData, updateDetails = () => null, submitStep }: Props) {
  const navigate = useNavigate();

  return (
    <div className='grid gap-6'>
      <div className='card p-8 grid gap-1'>
        <Translate value='create_approval.summary' className='text-neutral-1 text-lg-semibold' />
        <Translate
          value='create_approval.summary.description'
          className='text-neutral-3 text-sm-regular'
        />
      </div>
      <h2 className='text-center text-neutral-1 text-xs-bold'>{formData.modelName}</h2>
      <div className='flex justify-center w-full overflow-x-hidden'>
        <div className='flex overflow-x-auto pb-4'>
          {formData.appModelConfigs.map((config, index) => (
            <div key={index} className='relative flex items-center'>
              <LevelCard
                level={index + 1}
                feature={formData.feature}
                appStructure={formData.appStructure}
                approverCategory={formData.approverCategory}
                isEditMode={false}
                config={config}
                showCloseButton={formData.appModelConfigs.length > 1}
                removeLevel={() =>
                  updateDetails([
                    ...formData.appModelConfigs.slice(0, index),
                    ...formData.appModelConfigs.slice(index + 1),
                  ])
                }
              />
              {index !== formData.appModelConfigs.length - 1 && <DottedConnector />}
            </div>
          ))}
        </div>
      </div>
      <div className='flex gap-4 w-full justify-center mt-10'>
        <Button
          type='button'
          severity='contrast'
          className='w-60 inline-flex justify-center'
          onClick={() => navigate(`/manage/approvals?tab=${formData.feature}`)}
        >
          <Translate value='create_approval.cancel' />
        </Button>
        <Button
          type='button'
          severity='info'
          onClick={submitStep}
          className='w-60 inline-flex justify-center gap-2'
        >
          <Translate value='create_approval.save_and_proceed' />
        </Button>
      </div>
    </div>
  );
}

export default Step3;
