import { Button } from 'primereact/button';
import { useState } from 'react';
import { Translate } from '../../../../i18n/translate';
import { AddAlt } from '../../../../icons';
import BeneficiaryCreationModal from '../Dashboard/BenificieryCreationModal';

interface BeneficiaryDetailsProps {
  disabled: boolean;
  quoteId: string;
  paymentId: number;
  buyCurrency: string;
}

export const BeneficiaryDetails = ({
  disabled,
  quoteId,
  paymentId,
  buyCurrency,
}: BeneficiaryDetailsProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className='relative'>
      {disabled && <div className='absolute inset-0 bg-white opacity-70 cursor-not-allowed z-10' />}
      <div className={(disabled ? 'opacity-50	' : '') + 'rounded-c8 shadow-c bg-white p-8'}>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='beneficiary.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='beneficiary.description' />
            </div>
          </div>
        </div>
        <Button
          severity='contrast'
          className='mt-8 h-[43px]'
          onClick={() => setVisible(true)}
          type='button'
        >
          <AddAlt />
          <Translate value='add_new_beneficiary.header' className='ml-1' />
        </Button>
      </div>
      <BeneficiaryCreationModal
        visible={visible}
        setVisible={setVisible}
        quoteId={quoteId}
        paymentId={paymentId}
        buyCurrency={buyCurrency}
      />
    </div>
  );
};
