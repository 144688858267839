import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import SearchDropDown from '../../../../../components/SearchDropdown';
import { Translate } from '../../../../../i18n/translate';
import { selectCurrentCustomer } from '../../../../../store/slices/main/mainSlice';
import { useSelector } from 'react-redux';

type Props = {
  initialSelectedUsers: Array<number>;
  feature: FeatureType;
  updateList: (users: Array<number>) => void;
};

const SelectNotifyUsers = ({ initialSelectedUsers, feature, updateList }: Props) => {
  const [selectedItems, setSelectedItems] = useState(initialSelectedUsers);

  const selectedCustomer = useSelector(selectCurrentCustomer);

  const queryClient = useQueryClient();

  const availableUsers = queryClient.getQueryData<Array<User>>([
    'getUsersByFeature',
    feature,
    selectedCustomer?.id,
  ]);

  return (
    <div className='h-full flex flex-col gap-4 overflow-hidden pb-6'>
      {!availableUsers || availableUsers.length === 0 ? (
        <div>No data</div>
      ) : (
        <div className='flex-grow grid grid-rows-[aut_1fr] gap-4 h-full overflow-hidden'>
          <SearchDropDown
            items={availableUsers.map((item) => ({
              id: item.id as number,
              label: item.userIdentity.firstName,
              subLabel: item.userIdentity.email,
            }))}
            emptyMessage='no_results'
            selectedItems={selectedItems}
            onSelectChange={(ids) => setSelectedItems(ids as Array<number>)}
          />
        </div>
      )}
      <div className='flex justify-end gap-2'>
        <Button severity='secondary' onClick={() => updateList([])}>
          <Translate value='clear_all' />
        </Button>
        <Button
          severity='info'
          disabled={!availableUsers || availableUsers.length === 0}
          onClick={() => updateList(selectedItems)}
        >
          <Translate value='save_selection' />
        </Button>
      </div>
    </div>
  );
};

export default SelectNotifyUsers;
