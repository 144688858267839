import { DataTable, DataTablePageEvent } from 'primereact/datatable';
import { Translate } from '../../../i18n/translate';
import { PaginatorTemplate } from '../../../components';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { ListChecked, TrashCan } from '@carbon/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NotificationService from '../notificationService';
import { Column } from 'primereact/column';
import './table.scss';
import ContentTemplate from './ContentTemplate';
import { useDispatch } from 'react-redux';
import { sendToast } from '../../../store/slices/main/mainSlice';
import { NOTIFICATION_STATUS } from '../../../types';

const Table = ({ type }: { type: string }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedNotifications, setSelectedNotifications] = useState<NotificationMessage[]>([]);
  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const { data, isLoading } = useQuery({
    queryKey: ['notifications_query', pageParams],
    queryFn: async () => {
      const data = await NotificationService.getNotifications(
        type,
        pageParams.page,
        pageParams.rows,
      );
      setTotalRecords(data?.totalElements ?? 0);
      return data?.content;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const markAllAsReadMutation = useMutation({
    mutationFn: async () => {
      const response = await NotificationService.markAllAsRead();
      return response;
    },
    onSuccess: () => {
      showToast('success', 'marked_all_notifications_as_read', '');
      queryClient.invalidateQueries({
        queryKey: ['notifications_query'],
      });
      queryClient.invalidateQueries({
        queryKey: ['notifications_query_sidebar'],
      });
    },
  });

  const markSelectedAsReadMutation = useMutation({
    mutationFn: async () => {
      const payload = {
        notificationIds: selectedNotifications.map(({ id }) => id),
      };
      const response = await NotificationService.readNotification(payload);
      return response;
    },
    onSuccess: (response) => {
      if (response?.data.success) {
        showToast('success', 'marked_selected_notifications_as_read', '');
        queryClient.invalidateQueries({
          queryKey: ['notifications_query'],
        });
        queryClient.invalidateQueries({
          queryKey: ['notifications_query_sidebar'],
        });
        setSelectedNotifications([]);
      }
    },
  });

  const deleteSelectedMutation = useMutation({
    mutationFn: async () => {
      const payload = {
        notificationIds: selectedNotifications.map(({ id }) => id),
      };
      const response = await NotificationService.deleteNotification(payload);
      return response;
    },
    onSuccess: (response) => {
      if (response?.data.success) {
        showToast('success', 'deleted_selected_notifications', '');
        queryClient.invalidateQueries({
          queryKey: ['notifications_query'],
        });
        queryClient.invalidateQueries({
          queryKey: ['notifications_query_sidebar'],
        });
        setSelectedNotifications([]);
      }
    },
  });

  const header = (
    <div className='flex flex-col md:flex-row gap-3 justify-between'>
      <div>
        <div className='text-neutral-1 text-lg-semibold'>
          <Translate value='total_notification' />
          <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
            {totalRecords}
          </span>
        </div>
      </div>
      <div className='flex justify-around gap-3'>
        {type === NOTIFICATION_STATUS.UNREAD && !selectedNotifications.length && (
          <Button
            severity='info'
            className='p-button-text'
            onClick={() => {
              markAllAsReadMutation.mutate();
            }}
          >
            <ListChecked size={20} />
            <Translate value='mark_all_as_read' className='pl-2' />
          </Button>
        )}
        {!!selectedNotifications.length && (
          <>
            <Button
              severity='danger'
              className='p-button-text'
              onClick={() => {
                deleteSelectedMutation.mutate();
              }}
            >
              <TrashCan />
              <Translate value='delete' className='pl-2' />
            </Button>
            <Button
              severity='info'
              className='p-button-text'
              onClick={() => {
                markSelectedAsReadMutation.mutate();
              }}
            >
              <ListChecked size={20} />
              <Translate value='mark_as_read' className='pl-2' />
            </Button>
          </>
        )}
      </div>
    </div>
  );

  return (
    <DataTable
      className='notifications-table'
      header={header}
      value={data}
      loading={isLoading}
      paginator={true}
      lazy
      first={pageParams.first}
      rows={pageParams.rows}
      totalRecords={totalRecords}
      onPage={onPage}
      paginatorTemplate={PaginatorTemplate}
      selectAll={false}
      selectionMode={'checkbox'}
      selection={selectedNotifications}
      onSelectionChange={(e: { value: NotificationMessage[] }) => setSelectedNotifications(e.value)}
      dataKey='id'
    >
      <Column selectionMode='multiple' />
      <Column
        field='description'
        body={(rowData) => <ContentTemplate rowData={rowData} />}
        className='w-full'
      />
    </DataTable>
  );
};

export default Table;
