import { InformationFilled } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RawHtmlContent, Translate } from '../../i18n/translate';
import { useAppDispatch } from '../../store';
import { fethCurrentUser } from '../../store/slices/main/actions';
import {
  selectAoboClient,
  selectCurrentCustomer,
  sendToast,
  setAoboClient,
  setCurrentCustomer,
} from '../../store/slices/main/mainSlice';
import { injectAoboCustoemrID } from '../../utils/apiClient';
import { AoboDropdown } from './AoboDropdown';

const AoboHeader = () => {
  const dispatch = useAppDispatch();
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [parentClient] = useState<Client | null>(selectedCustomer);
  const selectedAoboClient = useSelector(selectAoboClient);
  const navigate = useNavigate();

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const exitAobo = () => {
    injectAoboCustoemrID(null);
    showToast('success', 'aobo.remove.title', 'aobo.remove.description');
    dispatch(fethCurrentUser());
    dispatch(setCurrentCustomer(parentClient));
    dispatch(setAoboClient(undefined));
    navigate('/');
  };

  if (!selectedAoboClient) {
    return <></>;
  }

  return (
    <div className='flex justify-center text-16-bold p-1 border border-warning-border-subtle bg-warning-surface-light align-middle'>
      <div className='flex justify-start p-2'>
        <div className='mr-1'>
          <InformationFilled fill='var(--warning-content-default)' size={21} />
        </div>
        <div className='text-warning-content-dark text-sm-regular'>
          <RawHtmlContent
            htmlContent='aobo.warning.header'
            translateValues={{ clientName: selectedAoboClient?.label }}
          />
        </div>
      </div>
      <Button
        severity='secondary'
        className='border !border-warning-bg min-w-28 align-middle !h-[32px] !p-0'
        onClick={() => {
          exitAobo();
        }}
      >
        <Translate value='aobo.exit' className='text-error-1 m-auto' />
      </Button>
      <div>
        <AoboDropdown fromAoboHeader={true} />
      </div>
    </div>
  );
};

export default AoboHeader;
