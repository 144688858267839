import { PickListChangeEvent } from 'primereact/picklist';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CurrencyPickList from '../../../../components/CurrencyPicklist';
import Select from '../../../../components/Select';
import useRole from '../../../../hooks/useRoles';
import { translateWithValues } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import {
  selectCurrentCustomer,
  selectLoading,
  sendToast,
} from '../../../../store/slices/main/mainSlice';
import { fetchBuyCurrency, fetchSellCurrency } from '../../../../store/slices/refdata/actions';
import {
  selectBuyCurrencies,
  selectCustomerCurrencies,
  selectSellCurrencies,
} from '../../../../store/slices/refdata/refdataSlice';
import { CustomerType } from '../../../../types';
import { CURRENCY_ACTIONS } from '../../configurations-constants';
import currencyRole from '../currencyRole';
import CurrencyService from './currencyService';

const AvailableCurrencies = () => {
  const [source, setSource] = useState<Option[]>([]);
  const [target, setTarget] = useState<Option[]>([]);
  const [isSellOption, setIsSellOption] = useState(false);
  const [currencyAction, setCurrencyAction] = useState(CURRENCY_ACTIONS[0].value);
  const [selectedCurrencies, setSelectedCurrencies] = useState<AvailableCurrencies | undefined>();
  const currentCustomer = useSelector(selectCurrentCustomer);
  const buyCurrencies = useSelector(selectBuyCurrencies);
  const sellCurrencies = useSelector(selectSellCurrencies);
  const customerCurrencies = useSelector(selectCustomerCurrencies);

  const isLoading = useSelector(selectLoading);
  const dispatch = useAppDispatch();
  const { hasRole } = useRole();

  useEffect(() => {
    if (!currentCustomer) {
      return;
    }
    if (currentCustomer?.customerType !== CustomerType.SERVICE_PROVIDER) {
      CurrencyService.getAvailableCurrencies().then((data) => {
        setSelectedCurrencies(data);
      });
    } else {
      const _selectedCurrencies: AvailableCurrencies = {
        clientBuyCcy: [],
        clientSellCcy: [],
      };
      customerCurrencies.map((currency) => {
        if (!currency.disabled) {
          _selectedCurrencies.clientBuyCcy.push(currency.isocode);
          _selectedCurrencies.clientSellCcy.push(currency.isocode);
        }
      });
      setSelectedCurrencies(_selectedCurrencies);
    }
  }, [currentCustomer]);

  const handleOptionChange = (e: { value: string }) => {
    setIsSellOption(e.value === CURRENCY_ACTIONS[1].value);
    setCurrencyAction(e.value);
  };

  const handleChange = (event: PickListChangeEvent) => {
    const sortedSource = [...(event.source as Option[])].sort((a, b) =>
      a.label.localeCompare(b.label),
    );
    const sortedTarget = [...(event.target as Option[])].sort((a, b) =>
      a.label.localeCompare(b.label),
    );
    setSource(sortedSource);
    setTarget(sortedTarget);
    if (!isSellOption) buyCurrency(event.target);
    else sellCurrency(event.target);
  };

  const updateCustomerBuyCurrency = () => {
    if (currentCustomer && currentCustomer.id) {
      dispatch(fetchBuyCurrency(currentCustomer.id));
    }
  };

  const updateCustomerSellCurrency = () => {
    if (currentCustomer && currentCustomer.id) {
      dispatch(fetchSellCurrency(currentCustomer.id));
    }
  };

  const buyCurrency = async (targetList: Option[]) => {
    try {
      const response = await CurrencyService.postBuyCurrencies(
        currentCustomer?.id || 1,
        targetList.map((option) => {
          return {
            code: option.label,
          };
        }),
      );
      const { status, data } = response;
      const { result } = data;
      if (status == 200 && result.success) {
        updateCustomerBuyCurrency();
        dispatch(
          sendToast({
            severity: 'success',
            summary: 'success',
            detail: 'currenciesUpdated',
          }),
        );
      } else {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'error',
            detail: 'currenciesNotUpdated',
          }),
        );
      }
    } catch (error) {
      console.log(error);
      setTarget([]);
    }
  };

  const sellCurrency = async (targetList: Option[]) => {
    try {
      const response = await CurrencyService.postSellCurrencies(
        currentCustomer?.id || 1,
        targetList.map((option) => {
          return {
            code: option.label,
          };
        }),
      );
      const { status, data } = response;
      const { result } = data;
      if (status == 200 && result.success) {
        updateCustomerSellCurrency();
        dispatch(
          sendToast({
            severity: 'success',
            summary: 'success',
            detail: 'currenciesUpdated',
          }),
        );
      } else {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'error',
            detail: 'currenciesNotUpdated',
          }),
        );
      }
    } catch (error) {
      console.log(error);
      setTarget([]);
    }
  };

  const getBuyCurrency = () => {
    updateValues(buyCurrencies);
  };

  const getSellCurrency = () => {
    updateValues(sellCurrencies);
  };

  const updateValues = (data: string[]) => {
    const targetList = selectedCurrencies?.[!isSellOption ? 'clientBuyCcy' : 'clientSellCcy']
      .filter((currency: string) => data.includes(currency))
      .map((currency: string) => {
        return { label: currency, value: currency };
      });

    const sourceList = selectedCurrencies?.[!isSellOption ? 'clientBuyCcy' : 'clientSellCcy']
      .filter((currency: string) => currency != 'ALL')
      .filter((currency: string) => !data.includes(currency))
      .map((currency: string) => {
        return { label: currency, value: currency };
      });
    // sort target and source list
    if (targetList) {
      targetList.sort((a, b) => a.label.localeCompare(b.label));
      setTarget(targetList);
    }
    if (sourceList) {
      sourceList.sort((a, b) => a.label.localeCompare(b.label));
      setSource(sourceList);
    }
  };

  useEffect(() => {
    setSource([]);
    setTarget([]);
    if (selectedCurrencies) {
      if (!isSellOption) getBuyCurrency();
      else getSellCurrency();
    }
  }, [isSellOption, selectedCurrencies]);

  return (
    <div className='bg-white p-6 rounded-c8 border border-neutral-surface-dark'>
      <div className='flex justify-center'>
        <Select
          options={CURRENCY_ACTIONS.map((action) => ({
            ...action,
            name: translateWithValues(action.name),
          }))}
          value={currencyAction}
          onChange={handleOptionChange}
        />
      </div>
      <div className='flex justify-center pt-6'>
        <div className='p-6 border border-neutral-border rounded-c8 w-4/5'>
          <CurrencyPickList
            isLoading={isLoading}
            source={source}
            target={target}
            onChange={handleChange}
            sourceHeader={translateWithValues(
              isSellOption ? 'sell_currency_available' : 'buy_currency_available',
            )}
            destinationHeader={translateWithValues(
              isSellOption ? 'sell_currency_allowed' : 'buy_currency_allowed',
            )}
            hasEditPermission={hasRole(currencyRole.EditCurrency)}
          />
        </div>
      </div>
    </div>
  );
};

export default AvailableCurrencies;
