import { Logout, Notification, User } from '@carbon/icons-react';
import { useOktaAuth } from '@okta/okta-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Badge } from 'primereact/badge';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BreadCrumb, InheritedMenu } from '..';
import NotificationService from '../../modules/Notification/notificationService';
import NotificationSidebar from '../../modules/Notification/NotificationSideBar';
import UserprincipalService from '../../modules/UserPrinciple/userPrinicple';
import { selectLoading } from '../../store/slices/main/mainSlice';
import { NOTIFICATION_STATUS } from '../../types';
import { isToday } from '../../utils/helper';
import { AoboDropdown } from './AoboDropdown';
import AoboHeader from './AoboHeader';
import MultiCustomer from './MultiCustomer';

export default function Header() {
  const isLoading = useSelector(selectLoading);
  const menu = useRef<Menu>(null);
  const { oktaAuth } = useOktaAuth();
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [activeNotificationCount, setActiveNotificationCount] = useState(0);
  const queryClient = useQueryClient();

  const { data: notificationData, isFetching } = useQuery({
    queryKey: ['notifications_query_sidebar'],
    queryFn: async () => {
      const data = await NotificationService.getNotifications(NOTIFICATION_STATUS.UNREAD, 0, 1000);
      const dataGrouped = data?.content.reduce(
        (acc: { today: NotificationMessage[]; older: NotificationMessage[] }, notification) => {
          if (isToday(notification.createdAt)) {
            acc.today.push(notification);
          } else {
            acc.older.push(notification);
          }
          return acc;
        },
        { today: [], older: [] },
      );
      setActiveNotificationCount(data?.content.length ?? 0);
      return dataGrouped;
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchInterval: 30000,
  });

  const actions: MenuItem[] = [
    {
      label: 'logout',
      icon: <Logout />,
      onClick: () => {
        oktaAuth
          .signOut({
            postLogoutRedirectUri: window.location.origin + '/logout/callback',
          })
          .then(() => {
            UserprincipalService.signOut().then(async () => {
              await oktaAuth.closeSession();
              oktaAuth.tokenManager.clear();
            });
          });
      },
    },
  ];

  return (
    <div className='fixed bg-white z-[1100] w-full sm:w-[inherit]'>
      <NotificationSidebar
        notifications={notificationData}
        loading={isFetching}
        visible={notificationVisible}
        setFilterModalToggle={setNotificationVisible}
      />
      <div className='flex justify-between items-center h-[78px] p-2.5 border-b border-neutral-border'>
        <BreadCrumb />
        <div className='flex items-center mr-3.5'>
          <MultiCustomer />
          <div className='ml-3'></div>
          <AoboDropdown />
          {/* <Sun className='fill-primary ml-[24px]' size={24} /> TODO */}
          <div
            className='relative inline-block cursor-pointer'
            onClick={() => {
              queryClient.invalidateQueries({ queryKey: ['notifications_query_sidebar'] });
              setNotificationVisible(true);
            }}
          >
            <Notification className='fill-primary ml-[24px]' size={24} />
            {!!activeNotificationCount && (
              <Badge
                value={activeNotificationCount}
                className='bg-primary absolute top-0 right-0 translate-x-2.5 -translate-y-1/2'
              />
            )}
          </div>
          <div
            onClick={(event) => {
              menu.current?.toggle(event);
            }}
          >
            <User className='fill-primary ml-[24px]' size={24} />
          </div>

          <InheritedMenu items={actions} ref={menu} popupAlign='right' />
        </div>
      </div>
      {isLoading && (
        <div className='w-full h-1 bg-primary'>
          <div className='h-full bg-white animate-pulse'></div>
        </div>
      )}
      {<AoboHeader />}
    </div>
  );
}
