import { useMutation } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { RadioCard, RichEditor, TextInput } from '../../../../components';
import useRole from '../../../../hooks/useRoles';
import { Translate } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import { sendToast } from '../../../../store/slices/main/mainSlice';
import configurationRole from '../../configurationRole';
import EmailSettingsService from '../EmailSettingsService';

type Props = {
  customerId: number;
  owningCustomerId: number | undefined;
  initialData: CustomerEmailSettingsType | null | undefined;
};

const EmailSettingsForm = ({ customerId, owningCustomerId, initialData }: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    unregister,
    formState: { errors, isDirty },
  } = useForm<CustomerEmailSettingsType>({
    defaultValues: {
      emailProvider: '',
      emailFrom: '',
      host: '',
      emailProviderPort: '',
      username: '',
      password: '',
      spfRecord: '',
      emailSignature: '',
    },
  });
  const emailProvider = watch('emailProvider');
  const { hasRole } = useRole();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (initialData) {
      reset({
        emailProvider: initialData.emailProvider ?? 'stonexProvider',
        emailFrom: initialData.emailFrom ?? '',
        host: initialData.host ?? '',
        emailProviderPort: initialData.emailProviderPort ?? '',
        username: initialData.username ?? '',
        password: initialData.password ?? '',
        spfRecord: initialData.spfRecord ?? '',
        emailSignature: initialData.emailSignature ?? '',
      });
    }
  }, [initialData, reset]);

  useEffect(() => {
    if (emailProvider === 'stonexProvider') {
      unregister(['host', 'emailProviderPort', 'username', 'password', 'spfRecord']);
    }
  }, [emailProvider]);

  const submitForm: SubmitHandler<CustomerEmailSettingsType> = (data) => {
    if (!isDirty) {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'error',
          detail: 'email.settings.no.changes.detected',
        }),
      );
      return;
    }
    const newData: CustomerEmailSettingsType = {
      id: initialData?.id,
      owningCustomerId,
      lastUpdatedBy: customerId,
      status: 'PENDING',
      version: initialData?.version ? initialData.version + 1 : 0,
      ...data,
    };
    mutation.mutate(newData);
  };

  const providers = [
    {
      value: 'stonexProvider',
      label: 'stonex_provider',
    },
    {
      value: 'smtpProvider',
      label: 'smtp_provider',
    },
  ];

  const mutation = useMutation({
    mutationFn: async (newData: CustomerEmailSettingsType) => {
      let response;
      if (initialData) {
        response = await EmailSettingsService.updateCustomerEmailSettings(customerId, newData);
      } else {
        response = await EmailSettingsService.createCustomerEmailSettings(customerId, newData);
      }
      return response;
    },
    onSuccess: (response) => {
      const { data } = response;
      if (data.result?.success) {
        const emailSettings = data.data;
        reset({
          emailProvider: emailSettings?.emailProvider ?? 'stonexProvider',
          emailFrom: emailSettings?.emailFrom?.trim() ?? '',
          host: emailSettings?.host?.trim() ?? '',
          emailProviderPort: emailSettings?.emailProviderPort ?? 0,
          username: emailSettings?.username?.trim() ?? '',
          password: emailSettings?.password?.trim() ?? '',
          spfRecord: emailSettings?.spfRecord?.trim() ?? '',
          emailSignature: emailSettings?.emailSignature ?? '',
        });
        dispatch(
          sendToast({
            severity: 'success',
            summary: 'Email settings Updated',
            detail: 'email.settings.update.success',
          }),
        );
      } else {
        dispatch(
          sendToast({
            severity: 'error',
            summary: 'Failed',
            detail: 'email.settings.update.error',
          }),
        );
      }
    },
    onError: (response) => {
      console.error(response);
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'Failed',
          detail: 'email.settings.update.error',
        }),
      );
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submitForm)(e);
      }}
      className='mx-8 px-6 py-6 bg-white rounded-c8 border border-neutral-surface-dark'
    >
      <div className='flex justify-between pb-4 border-b border-neutral-border-2'>
        <div>
          <div className='text-lg-semibold'>
            <Translate value='email_server_details' />
          </div>
          <div className='text-neutral-3 mt-1'>
            <Translate value='subheading_placeholder' />
          </div>
        </div>
        {hasRole(
          `${configurationRole.editConfiguration}&${configurationRole.viewConfiguration}`,
        ) && (
          <div>
            <Button
              severity='info'
              type='submit'
              disabled={mutation.isPending}
              loading={mutation.isPending}
            >
              <Translate value='save' />
            </Button>
          </div>
        )}
      </div>
      <div className='grid grid-cols-[auto_1fr] gap-y-10'>
        <Translate
          value='email_provider'
          className='text-neutral-1 text-sm-medium pr-14 border-b border-neutral-border-2 py-10'
        />
        <div className='border-b border-neutral-border-2 py-10 flex gap-10'>
          {providers.map((provider) => (
            <RadioCard
              control={control}
              value={provider.value}
              key={provider.value}
              label={provider.label}
              name='emailProvider'
            />
          ))}
        </div>
        {emailProvider !== 'stonexProvider' && (
          <>
            <Translate value='email_host' className='text-neutral-1 text-sm-medium pr-14' />
            <TextInput
              formRegister={register('host', {
                required: true,
                onChange: (e) => {
                  const trimmedValue = e.target.value.trimStart();
                  setValue('host', trimmedValue);
                },
              })}
              error={errors.host}
              errorLabel='email_host'
              placeholder='email_host'
              className='w-[300px]'
            />
            <Translate value='port_number' className='text-neutral-1 text-sm-medium pr-14' />
            <TextInput
              formRegister={register('emailProviderPort', {
                required: true,
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'port.invalid',
                },
                onChange: (e) => {
                  const trimmedValue = e.target.value.trimStart();
                  setValue('emailProviderPort', trimmedValue);
                },
              })}
              error={errors.emailProviderPort}
              errorLabel='port_number'
              placeholder='port_number'
              className='w-[300px]'
            />
            <Translate value='user_name' className='text-neutral-1 text-sm-medium pr-14' />
            <TextInput
              formRegister={register('username', {
                required: true,
                onChange: (e) => {
                  const trimmedValue = e.target.value.trimStart();
                  setValue('username', trimmedValue);
                },
              })}
              error={errors.username}
              errorLabel='user_name'
              placeholder='user_name'
              className='w-[300px]'
            />
            <Translate value='password' className='text-neutral-1 text-sm-medium pr-14' />
            <TextInput
              type='password'
              formRegister={register('password', {
                required: true,
                onChange: (e) => {
                  const trimmedValue = e.target.value.trimStart();
                  setValue('password', trimmedValue);
                },
              })}
              error={errors.password}
              errorLabel='password'
              placeholder='password'
              className='w-[300px]'
            />
            <Translate value='SPF_record' className='text-neutral-1 text-sm-medium pr-14' />
            <TextInput
              formRegister={register('spfRecord', {
                required: true,
                onChange: (e) => {
                  const trimmedValue = e.target.value.trimStart();
                  setValue('spfRecord', trimmedValue);
                },
              })}
              error={errors.spfRecord}
              errorLabel='SPF_record'
              placeholder='SPF_record'
              className='w-[300px]'
            />
          </>
        )}
        <Translate
          value='sender_from_address'
          className='text-neutral-1 text-sm-medium pr-14 border-y border-neutral-border-2 py-10'
        />
        <div className='border-y border-neutral-border-2 py-10'>
          <TextInput
            formRegister={register('emailFrom', {
              required: true,
              onChange: (e) => {
                const trimmedValue = e.target.value.trimStart();
                if (trimmedValue.length > 100) {
                  setValue('emailFrom', trimmedValue.slice(0, 100));
                } else {
                  setValue('emailFrom', trimmedValue);
                }
              },
            })}
            error={errors.emailFrom}
            errorLabel='sender_from_address'
            placeholder='sender_from_address'
            className='w-[300px]'
          />
        </div>
        <Translate value='email_signature' className='text-neutral-1 text-sm-medium pr-14' />
        <div className='max-w-[500px]'>
          <RichEditor
            value={watch('emailSignature')}
            onChange={(e) => setValue('emailSignature', e.htmlValue || '')}
            name='emailSignature'
          />
        </div>
      </div>
    </form>
  );
};
export default EmailSettingsForm;
