const Close = ({ size = 20, className = '' }: { size?: number; className?: string }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 5.875L14.125 5L10 9.125L5.875 5L5 5.875L9.125 10L5 14.125L5.875 15L10 10.875L14.125 15L15 14.125L10.875 10L15 5.875Z'
        fill='url(#paint0_linear_5890_1745)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_5890_1745'
          x1='10'
          y1='5'
          x2='10'
          y2='15'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='var(--primary-gradient-to)' />
          <stop offset='1' stopColor='var(--primary-gradient-from)' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Close;
