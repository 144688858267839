/* eslint-disable @typescript-eslint/no-misused-promises */
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ApprovalConfirm, DropdownInput, TextInput } from '../../../../../components';
import { Translate } from '../../../../../i18n/translate';
import {
  selectCurrentCustomer,
  selectLoading,
  sendToast,
} from '../../../../../store/slices/main/mainSlice';
import { AddOrEditOrView, Status } from '../../../../../types';
import { EMAIL_VALIDATION_REGEX, USER_STATUS_OPTIONS } from '../../../../Client/clientConstants';
import UserService from '../../../userService';

const UserDetails = ({
  onSuccess,
  userId,
  customerId,
  mode,
}: {
  onSuccess: (id: number) => void;
  userId: string | undefined;
  customerId?: number;
  mode: AddOrEditOrView;
}) => {
  const isEdit = !!userId;
  const dispatch = useDispatch();
  const [owningCustomerId, setOwningCustomerId] = useState<number>(1);
  const [canBeApproved, setCanBeApproved] = useState<boolean>(false);
  const [approved, setApproved] = useState(false);
  const currentCustomer = useSelector(selectCurrentCustomer);
  const isLoading = useSelector(selectLoading);
  const navigate = useNavigate();
  const {
    control,
    unregister,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<UserFormData>();

  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [apporveOrject, setApporveOrject] = useState<'approve' | 'reject'>();
  const [currentEditUser, setCurrentEditUser] = useState<User | null>(null);

  useEffect(() => {
    if (currentCustomer && currentCustomer.id) {
      setOwningCustomerId(currentCustomer.id);
    }
    if (customerId) {
      setOwningCustomerId(customerId);
    }
  }, [currentCustomer, customerId]);

  const getApprovalItemDetails = async () => {
    try {
      if (userId == null) return;
      const response = await UserService.getApprovalItemDetails(userId);
      const { status } = response;
      if (status === 200 && response?.data?.result?.success) {
        const {
          data: {
            data: { data: user },
          },
        } = response;
        setCurrentEditUser(user);
        setCanBeApproved(user.canBeApprovedByCurrentUser ?? false);
        const formData: UserFormData = {
          emailId: user.userIdentity.email,
          firstName: user.userIdentity.firstName,
          lastName: user.userIdentity.lastName,
          phone: user.userIdentity.mobilePhone,
          status: user.status,
        };
        reset(formData);
      }
    } catch (error) {
      console.error('Error fetching approval item details:', error);
    }
  };

  useEffect(() => {
    if (![AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)) {
      unregister('status');
    }
  }, [mode]);

  const getCurretUser = async () => {
    if (userId) {
      const data = await UserService.getUserById(userId);
      if (data) {
        setCurrentEditUser(data);
        if (data.owningCustomerId) {
          setOwningCustomerId(data.owningCustomerId);
        }
        if (mode == AddOrEditOrView.Approve && userId) {
          getApprovalItemDetails();
        } else {
          const formData: UserFormData = {
            emailId: data.userIdentity.email,
            firstName: data.userIdentity.firstName,
            lastName: data.userIdentity.lastName,
            phone: data.userIdentity.mobilePhone,
            status: data.status,
          };
          reset(formData);
        }
      }
    }
  };

  useEffect(() => {
    getCurretUser();
  }, [userId]);

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const addUserDetails = async (userformData: UserFormData) => {
    if (!isValid) {
      return;
    }
    if (!isDirty) {
      showToast('error', 'error', 'user.not.dirty.error');
      return;
    }
    const submitData: SubmitUserData = {
      userIdentity: {
        firstName: userformData.firstName,
        lastName: userformData.lastName,
        mobilePhone: userformData.phone,
        email: userformData.emailId,
      },
      owningCustomerId: owningCustomerId,
    };
    if (userId) submitData['id'] = userId;

    const response = await UserService.createUser([submitData]);
    const { status, data } = response;
    if (status !== 200) {
      showToast('error', 'error', 'user.save.error');
      return;
    } else {
      const { result } = data[0];
      if (result.success) {
        showToast(
          'success',
          'success',
          isEdit ? 'userUpdatedSuccessfully' : 'userCreatedSuccessfully',
          {
            email: data[0].data.userIdentity.email,
          },
        );
        if (data[0].data?.id) onSuccess(data[0].data.id);
      } else {
        const { errors } = result;
        showToast('error', 'error', (errors[0] && errors[0].errorTextCode) ?? '', {
          ...errors[0],
          email: submitData.userIdentity.email,
        });
        return;
      }
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <>
      <ApprovalConfirm
        showApproveConfirm={showApproveConfirm}
        setShowApproveConfirm={(v: boolean, _approved: boolean) => {
          setApproved(_approved);
          getCurretUser();
          setShowApproveConfirm(v);
        }}
        apporveOrject={apporveOrject}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        approveFn={UserService.approveOrRject}
        id={currentEditUser?.id}
        type='user'
      />

      <>
        <form onSubmit={handleSubmit(addUserDetails)}>
          <div className='!p-8 card'>
            <div className='flex justify-between flex-wrap'>
              <div>
                <div className='text-sm-bold text-secondary-text'>
                  <Translate
                    value={`${[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode) ? 'user.view.title' : 'add_user.user_details.title'}`}
                  />
                </div>
                <div className='text-neutral-3'>
                  <Translate value='add_user.user_details.description' />
                </div>
              </div>
              {[AddOrEditOrView.Approve].includes(mode) &&
                currentEditUser &&
                !approved &&
                canBeApproved &&
                (currentEditUser.approvalStatus == Status.PENDING_APPROVAL ||
                  currentEditUser.approvalStatus == Status.PENDING_CREATION) && (
                  <div>
                    <Button
                      disabled={isLoading}
                      severity='secondary'
                      onClick={(event) => {
                        event.preventDefault();
                        setShowApproveConfirm(true);
                        setApporveOrject('reject');
                      }}
                    >
                      <Translate value='reject' className='ml-1' />
                    </Button>

                    <Button
                      disabled={isLoading}
                      severity='info'
                      className='ml-2'
                      onClick={(event) => {
                        event.preventDefault();
                        setShowApproveConfirm(true);
                        setApporveOrject('approve');
                      }}
                    >
                      <Translate value='approve' className='ml-1' />
                    </Button>
                  </div>
                )}
            </div>
            <div className='lg:mx-5 mt-12 mb-6 grid md:grid-cols-2 lg:grid-cols-3 gap-5'>
              <TextInput
                formRegister={register('emailId', {
                  required: true,
                  pattern: {
                    value: EMAIL_VALIDATION_REGEX,
                    message: 'invalid_email_address',
                  },
                })}
                error={errors.emailId}
                label='email_id'
                placeholder='email_id'
                disabled={isEdit || [AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
                isRequired
              />

              <TextInput
                formRegister={register('firstName', { required: true })}
                error={errors.firstName}
                label='first_name'
                placeholder='first_name'
                isRequired
                disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
              />

              <TextInput
                formRegister={register('lastName', { required: true })}
                error={errors.lastName}
                label='last_name'
                placeholder='last_name'
                isRequired
                disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
              />

              <TextInput
                formRegister={register('phone', { required: true })}
                error={errors.phone}
                id='phone'
                label='phone'
                placeholder='phone_placeholder'
                isRequired
                disabled={[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode)}
              />

              {[AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode) && (
                <DropdownInput
                  name='status'
                  label='status'
                  placeholder='status'
                  control={control}
                  options={USER_STATUS_OPTIONS}
                  error={errors.status}
                  disabled={true}
                />
              )}
            </div>
          </div>
          {![AddOrEditOrView.View, AddOrEditOrView.Approve].includes(mode) && (
            <div className='flex items-center justify-center mt-10 gap-5'>
              <Button type='button' severity='contrast' className='!px-7' onClick={handleCancel}>
                <Translate value='cancel' />
              </Button>
              <Button
                type='submit'
                severity='info'
                disabled={isLoading}
                loading={isLoading}
                className='!px-7'
                onClick={() => {
                  if (!isValid) {
                    showToast(
                      'error',
                      'client.field.validation.error',
                      'user.field.validation.error.detail',
                    );
                    return;
                  }
                }}
              >
                <Translate value='user.create.submit' />
              </Button>
            </div>
          )}
        </form>
      </>
    </>
  );
};

export default UserDetails;
