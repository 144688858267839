import { Download, SendAlt } from '@carbon/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ApprovalConfirm, FormatAmount, Status } from '../../../../components';
import getCountryISO2, {
  getCountryISO3,
} from '../../../../components/CountrySelect/isoncCodeMapping';
import { Translate, translateWithValues } from '../../../../i18n/translate';
import { PageHeadContext } from '../../../../providers/PageHead';
import { useAppDispatch } from '../../../../store';
import { sendToast } from '../../../../store/slices/main/mainSlice';
import { selectCustomerCurrencies } from '../../../../store/slices/refdata/refdataSlice';
import { AddOrEditOrView } from '../../../../types';
import PaymentService from '../../paymentService';
import CountdownTimer from '../CountdownTimer';

export default function QuickQuoteSummary({ mode }: { mode: AddOrEditOrView }) {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  const [quoteId, setQuoteId] = useState<string | null>(null);
  const [quoteExpired, setQuoteExpired] = useState(false);

  const [searchParams] = useSearchParams();

  const orderId = searchParams.get('orderId') as number | null;
  const orderDetailsQuery = useQuery({
    queryKey: ['order_details', orderId],
    queryFn: async () => {
      const response = await PaymentService.getOrderById(orderId!);
      setQuoteId(response.payments[0].quote.quoteId);
      return response;
    },
    enabled: !!orderId,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    setPageHeadData({
      title: 'rate_requests.title',
      description: 'rate_requests.description',
    });
  }, [setPageHeadData]);

  const downloadMutation = useMutation({
    mutationFn: async () => {
      const response = await PaymentService.downloadDisclosureStatement(orderId!, quoteId!);
      return response;
    },
    onError: () => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'download_failed',
          detail: 'disclosure_statement_download_failed',
        }),
      );
    },
  });

  if (orderDetailsQuery.isLoading) {
    return <div className='grid place-content-center'>Loading...</div>;
  }

  if (orderDetailsQuery.isError) {
    return <div className='grid place-content-center'>Error fetching order summary</div>;
  }

  if (!quoteId) {
    return <div className='grid place-content-center'>No quote id</div>;
  }

  return (
    <div className='grid gap-6 py-6'>
      {mode == AddOrEditOrView.Add && (
        <div className='flex ml-auto gap-4'>
          <Button
            type='button'
            severity='contrast'
            className='gap-2'
            onClick={() => void downloadMutation.mutate()}
          >
            <Download color='var(--primary-gradient-from)' />
            <Translate value='downloadLabel' />
          </Button>
          <Button type='button' severity='contrast' className='gap-2'>
            <SendAlt color='var(--primary-gradient-from)' />
            <Translate value='send_via_email' />
          </Button>
        </div>
      )}
      <div className='grid grid-cols-[auto_1fr] gap-5'>
        <CustomerDetails />
        <AcceptedRate
          quoteId={quoteId}
          updateQuoteId={setQuoteId}
          quoteExpired={quoteExpired}
          setQuoteExpired={(value) => setQuoteExpired(value)}
          mode={mode}
        />
        <BeneficiarySummary />
      </div>
      {mode == AddOrEditOrView.Add && (
        <SubmitButtons quoteId={quoteId} quoteExpired={quoteExpired} />
      )}
      {mode == AddOrEditOrView.Approve && <ApprovalButtons quoteExpired={quoteExpired} />}
    </div>
  );
}

function ApprovalButtons({ quoteExpired }: { quoteExpired: boolean }) {
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [apporveOrject, setApporveOrject] = useState<'approve' | 'reject'>();
  const [approved, setApproved] = useState(false);
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId') as number | null;
  const navigate = useNavigate();

  if (approved) {
    return <></>;
  }

  return (
    <>
      <ApprovalConfirm
        showApproveConfirm={showApproveConfirm}
        setShowApproveConfirm={(v, _approved) => {
          setShowApproveConfirm(v);
          setApproved(_approved);
          if (_approved) {
            if (apporveOrject == 'approve') {
              navigate('/payment/rate-requests');
            }
          }
        }}
        apporveOrject={apporveOrject}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        approveFn={PaymentService.approveOrRject}
        id={orderId}
        type='payment'
      />

      <div className='flex justify-center gap-6'>
        <Button
          severity='secondary'
          disabled={quoteExpired}
          onClick={() => {
            setApporveOrject('reject');
            setShowApproveConfirm(true);
          }}
        >
          <Translate value='reject' />
        </Button>

        <Button
          severity='info'
          disabled={quoteExpired}
          className='ml-[10px]'
          onClick={() => {
            setApporveOrject('approve');
            setShowApproveConfirm(true);
          }}
        >
          <Translate value='approve' />
        </Button>
      </div>
    </>
  );
}

function SubmitButtons({ quoteId, quoteExpired }: { quoteId: string; quoteExpired: boolean }) {
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get('orderId') as number | null;

  const [customerOptIn, setCustomerOptIn] = useState(true);
  const [requesterOptIn, setRequesterOptIn] = useState(true);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const quickQuoteByIdQuery = useQuery<
    Awaited<ReturnType<typeof PaymentService.getQuickQuoteById>>
  >({
    queryKey: ['quick_quote', quoteId],
    retry: false,
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const initiateOrderApprovalMutation = useMutation({
    mutationFn: async () => {
      const response = await PaymentService.initiateOrderApproval(
        orderId!,
        customerOptIn,
        requesterOptIn,
      );
      return response;
    },
    onSuccess: () => {
      dispatch(
        sendToast({
          severity: 'success',
          summary: 'order_approval.success',
        }),
      );
      navigate('/payment/rate-requests');
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'order_approval.failed',
          detail: error.message,
        }),
      );
    },
  });

  return (
    <div className='grid gap-6 mt-6 place-items-center'>
      <div className='border border-primary-light rounded-lg bg-primary-surface-light p-5 flex items-center gap-4 w-fit'>
        <Checkbox onChange={(e) => setCustomerOptIn(Boolean(e.checked))} checked={customerOptIn} />
        <div className='text-sm-semibold text-primary-dark-content'>
          <Translate value='order_approval.customer_opt_in' />
        </div>
      </div>
      <div className='border border-primary-light rounded-lg bg-primary-surface-light p-5 flex items-center gap-4 w-fit'>
        <Checkbox
          onChange={(e) => setRequesterOptIn(Boolean(e.checked))}
          checked={requesterOptIn}
        />
        <div className='text-sm-semibold text-primary-dark-content'>
          <Translate value='order_approval.requester_opt_in' />
        </div>
      </div>
      <div className='flex justify-center gap-6'>
        <Button
          type='button'
          severity='contrast'
          onClick={() => {
            navigate('/payment/rate-requests');
          }}
        >
          <Translate value={'return'} />
        </Button>
        <Button
          severity='info'
          className='w-80 inline-flex gap-2 justify-center items-center'
          disabled={
            quickQuoteByIdQuery.isLoading ||
            quickQuoteByIdQuery.isError ||
            initiateOrderApprovalMutation.isPending ||
            quoteExpired
          }
          loading={initiateOrderApprovalMutation.isPending}
          onClick={() => {
            initiateOrderApprovalMutation.mutate();
          }}
        >
          <Translate value={'submit_for_approval'} />
        </Button>
      </div>
    </div>
  );
}

function CustomerDetails() {
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get('orderId') as number | null;

  const queryClient = useQueryClient();

  const orderDetailsData = queryClient.getQueryData<
    Awaited<ReturnType<typeof PaymentService.getOrderById>>
  >(['order_details', orderId]);

  const customerDetails = orderDetailsData?.payments[0].debitor;

  if (!customerDetails) {
    return <div>No customer details</div>;
  }

  return (
    <div className='card grid gap-4 place-content-start p-8'>
      <h2 className='text-lg-semibold text-neutral-1'>
        <Translate value='order.customer.summary.title' />
      </h2>
      {customerDetails.stoneXId && (
        <div>
          <div className='text-unselected text-sm-medium'>
            <Translate value='order.customer.summary.associate.stonx.id' />
          </div>
          <div className='text-sm-semibold text-neutral-2'>{customerDetails.stoneXId}</div>
        </div>
      )}

      <div>
        <div className='text-unselected text-sm-medium'>
          <Translate value='order.customer.summary.customer.type' />
        </div>
        <div className='text-sm-semibold text-neutral-2'>Ordering Customer</div>
      </div>
      <div>
        <div className='text-unselected text-sm-medium'>
          <Translate value='order.customer.summary.customer.name' />
        </div>
        <div className='text-sm-semibold text-neutral-2'>
          {customerDetails.customerShortName || customerDetails.customerLegalName}
        </div>
      </div>

      <div>
        <div className='text-unselected text-sm-medium'>
          <Translate value='order.customer.summary.customer.city' />
        </div>
        <div className='text-sm-semibold text-neutral-2'>{customerDetails.address?.city}</div>
      </div>
      <div>
        <div className='text-unselected text-sm-medium'>
          <Translate value='order.customer.summary.customer.country' />
        </div>
        <div className='flex gap-2 items-center'>
          {customerDetails.address?.country && (
            <>
              <div className='text-sm-semibold text-neutral-2'>
                {customerDetails.address.country}
              </div>
              <img
                alt={customerDetails.address?.country ?? ''}
                src={`/images/country_flags/${getCountryISO2(customerDetails.address.country)?.toLocaleLowerCase()}.svg`}
                className='w-[20px] h-[20px] rounded-full object-cover'
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function AcceptedRate({
  quoteId,
  updateQuoteId,
  quoteExpired,
  setQuoteExpired,
  mode,
}: {
  quoteId: string;
  updateQuoteId: (quoteId: string) => void;
  quoteExpired: boolean;
  setQuoteExpired: (value: boolean) => void;
  mode?: AddOrEditOrView;
}) {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId') as number | null;

  const queryClient = useQueryClient();

  const orderDetailsData = queryClient.getQueryData<
    Awaited<ReturnType<typeof PaymentService.getOrderById>>
  >(['order_details', orderId]);
  const initialQuoteDetails = orderDetailsData!.payments[0].quote;

  const dispatch = useAppDispatch();

  const currencies = useSelector(selectCustomerCurrencies);

  const quickQuoteByIdQuery = useQuery({
    queryKey: ['quick_quote', quoteId],
    queryFn: async () => {
      const response = await PaymentService.getQuickQuoteById(quoteId);
      const createdTimeWithZ = response.quote.createdTime.endsWith('Z')
        ? response.quote.createdTime
        : `${response.quote.createdTime}Z`;
      const now = new Date().getTime();
      const expiry = new Date(createdTimeWithZ).getTime() + 5 * 60 * 1000; // 5 minutes in milliseconds
      const timeLeft = Math.max(0, Math.floor((expiry - now) / 1000)); // time left in seconds
      setQuoteExpired(timeLeft <= 0);
      return response;
    },
    refetchOnWindowFocus: false,
    enabled: quoteId !== initialQuoteDetails.quoteId,
    retry: false,
  });

  const recalculateMutation = useMutation({
    mutationFn: async () => {
      const response = await PaymentService.reQuote(quoteId);
      return response;
    },
    onError: (error) => {
      dispatch(
        sendToast({
          severity: 'error',
          summary: 'recalculateQuoteError',
          detail: error.message,
        }),
      );
    },
    onSuccess: (data) => {
      updateQuoteId(data.quoteId || '');
    },
  });

  if (quickQuoteByIdQuery.isFetching) {
    return (
      <div className='grid place-content-center'>
        <Translate value='rate_requests.requote' />
      </div>
    );
  }

  if (quickQuoteByIdQuery.isError) {
    return (
      <div className='grid place-content-center'>
        <Translate value='rate_requests.fetch_error' />
      </div>
    );
  }

  const quickQuote = quickQuoteByIdQuery.data?.quote || initialQuoteDetails;

  return (
    <div className='card p-8 grid gap-4'>
      <div className='flex justify-between'>
        <h2 className='text-lg-semibold text-neutral-1'>
          <Translate value='rate_requests.accepted_rate' />
        </h2>
        <div className='flex justify-around'>
          <div className='p-1'>
            {orderDetailsData?.status && <Status status={orderDetailsData?.status} />}
          </div>
          {mode !== AddOrEditOrView.View && (
            <Button
              severity='contrast'
              disabled={!quoteExpired}
              onClick={() => recalculateMutation.mutate()}
            >
              <Translate value='rate_requests.refresh_quote' />
            </Button>
          )}
        </div>
      </div>
      <div className='flex gap-10'>
        <div className='grid gap-2'>
          <div className='text-sm-medium text-unselected'>
            <Translate value='rate_requests.buy_currency_and_amount' />
          </div>
          <div className='flex gap-2 items-center'>
            <img
              alt={quickQuote.buyCurrency ?? ''}
              src={`/images/c_flags_svg/${quickQuote.buyCurrency.toLocaleLowerCase()}.svg`}
              className='w-[20px] h-[20px] rounded-full object-cover'
            />
            <span>{quickQuote.buyCurrency || ''}</span>
            <FormatAmount
              number={parseFloat(quickQuote.buyAmount || '0')}
              maximumFractionDigits={
                currencies.find((currency) => quickQuote.buyCurrency == currency.isocode)
                  ?.amountPrecision
              }
            />
          </div>
        </div>
        <div className='grid gap-2'>
          <div className='text-sm-medium text-unselected'>
            <Translate value='rate_requests.sell_currency_and_amount' />
          </div>
          <div className='flex gap-2 items-center'>
            <img
              alt={quickQuote.sellCurrency ?? ''}
              src={`/images/c_flags_svg/${quickQuote.sellCurrency.toLocaleLowerCase()}.svg`}
              className='w-[20px] h-[20px] rounded-full object-cover'
            />
            <span>{quickQuote.sellCurrency || ''}</span>
            <FormatAmount
              number={parseFloat(quickQuote.sellAmount || '0')}
              maximumFractionDigits={
                currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                  ?.amountPrecision
              }
            />
          </div>
        </div>
      </div>
      {mode !== AddOrEditOrView.View && quoteExpired && (
        <div className='ml-auto w-fit'>
          <Status status='QuoteExpired' />
        </div>
      )}
      <div className='flex justify-between'>
        <div className='grid grid-cols-[auto_1fr] gap-x-16 gap-y-4'>
          <div>
            <Translate value='buy_amount' />
          </div>
          <div className='text-neutral-2'>
            <FormatAmount
              number={parseFloat(quickQuote.buyAmount || '0')}
              maximumFractionDigits={
                currencies.find((currency) => quickQuote.buyCurrency == currency.isocode)
                  ?.amountPrecision
              }
            />{' '}
            {quickQuote.buyCurrency}
          </div>
          <div>
            <Translate value='rate_requests.exchange_rate' />
          </div>
          <div className='text-neutral-2'>
            1 {quickQuote.buyCurrency} ={' '}
            <FormatAmount
              number={parseFloat(quickQuote.rate || '0')}
              maximumFractionDigits={
                currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                  ?.amountPrecision
              }
            />{' '}
            {quickQuote.sellCurrency}
          </div>
          <div>
            <Translate value='inverse' />
          </div>
          <div className='text-neutral-2'>{quickQuote.inverseRate ?? '-'}</div>
          <div>
            <Translate value='sellAmount' />
          </div>
          <div className='text-neutral-2'>
            <FormatAmount
              number={parseFloat(quickQuote.sellAmount || '0')}
              maximumFractionDigits={
                currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                  ?.amountPrecision
              }
            />{' '}
            {quickQuote.sellCurrency}
          </div>
          <div>
            <Translate value='rate_requests.fee' />
          </div>
          <div className='text-neutral-2'>
            <FormatAmount
              number={parseFloat(quickQuote.fee || '0')}
              maximumFractionDigits={
                currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                  ?.amountPrecision
              }
            />{' '}
            {quickQuote.sellCurrency}
          </div>
          <div>
            <Translate value='rate_requests.total_amount_payable' />
          </div>
          <div className='text-neutral-2'>
            <FormatAmount
              number={parseFloat(quickQuote.sellAmount + quickQuote.fee || '0')}
              maximumFractionDigits={
                currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                  ?.amountPrecision
              }
            />{' '}
            {quickQuote.sellCurrency}
          </div>
        </div>
        <div className='flex items-center gap-14 bg-primary-surface-light rounded-[8px] px-4 py-5'>
          <>
            <div className='grid'>
              <Translate value='quote_received' className='text-primary-dark-content' />
              <span className='text-primary-blue-surface-dark text-2xl-bold'>
                <FormatAmount
                  number={parseFloat(quickQuote?.sellAmount || '0')}
                  maximumFractionDigits={
                    currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                      ?.amountPrecision
                  }
                />
                {' ' + quickQuote.sellCurrency}
              </span>
              <div className='flex gap-2'>
                <div className='mt-4 bg-primary-blue text-primary-dark-content text-sm-medium w-fit p-2 h-fit rounded-lg'>
                  At 1 {quickQuote.sellCurrency + ' = '}
                  <FormatAmount
                    number={parseFloat(
                      (quickQuote.direction == 'IN' ? quickQuote.inverseRate : quickQuote?.rate) ??
                        '0',
                    )}
                    maximumFractionDigits={
                      currencies.find((currency) => quickQuote.sellCurrency == currency.isocode)
                        ?.ratePrecision
                    }
                  />
                  {' ' + quickQuote.buyCurrency}
                </div>
                {quickQuote.percentageProfit != undefined && (
                  <div
                    className={
                      (quickQuote.percentageProfit >= 0
                        ? 'border-success-content-default text-success-content-default '
                        : 'border-error-content-default text-error-content-default ') +
                      'rounded-[10px] border-[1px] text-lg-semibold flex items-center px-[20px] py-1 text-nowrap h-fit self-end'
                    }
                  >
                    <span>
                      {quickQuote.percentageProfit >= 0
                        ? translateWithValues('profit')
                        : translateWithValues('loss')}{' '}
                      {quickQuote.percentageProfit}%
                    </span>
                  </div>
                )}
              </div>
            </div>
            {mode !== AddOrEditOrView.View && quickQuote?.createdTime && (
              <CountdownTimer
                createdTime={quickQuote?.createdTime}
                onTimeOut={() => {
                  dispatch(
                    sendToast({
                      severity: 'error',
                      summary: 'alert',
                      detail: 'quoteExpired',
                    }),
                  );
                  setQuoteExpired(true);
                }}
              />
            )}
          </>
        </div>
      </div>
    </div>
  );
}

function BeneficiarySummary() {
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get('orderId') as number | null;

  const queryClient = useQueryClient();

  const orderDetailsData = queryClient.getQueryData<
    Awaited<ReturnType<typeof PaymentService.getOrderById>>
  >(['order_details', orderId]);

  const beneficiaryDetails = orderDetailsData?.payments[0].creditor;

  if (!beneficiaryDetails) {
    return <div>No beneficiary details</div>;
  }

  const buyCurrency = orderDetailsData.payments[0].buyCurrency;

  return (
    <div className='col-span-2 card p-8'>
      <h2 className='text-lg-semibold text-neutral-1 pb-6'>
        <Translate value='beneficiary.title' />
      </h2>
      <div className='py-6 border-y border-neutral-border'>
        <div className='text-md-semibold text-unselected mb-5'>
          <Translate value='rate_requests.beneficiary_currency_and_country' />
        </div>
        <div className='grid gap-5 grid-cols-3'>
          <div>
            <div className='text-sm-medium text-unselected'>
              <Translate value='currency' />
            </div>
            <div className='flex gap-2 items-center'>
              <img
                alt={buyCurrency ?? ''}
                src={`/images/c_flags_svg/${buyCurrency.toLocaleLowerCase()}.svg`}
                className='w-[20px] h-[20px] rounded-full object-cover'
              />
              <span>{buyCurrency || ''}</span>
            </div>
          </div>
          {beneficiaryDetails.countryOfResidence && (
            <div>
              <div className='text-sm-medium text-unselected'>
                <Translate value='beneficiary_country' />
              </div>
              <div className='flex gap-2 items-center'>
                <img
                  alt={beneficiaryDetails.countryOfResidence ?? ''}
                  src={`/images/country_flags/${beneficiaryDetails.countryOfResidence?.toLocaleLowerCase()}.svg`}
                  className='w-[20px] h-[20px] rounded-full object-cover'
                />
                <span>{getCountryISO3(beneficiaryDetails.countryOfResidence)}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='py-6'>
        <div className='text-md-semibold text-unselected mb-5'>
          <Translate value='rate_requests.beneficiary_bank_details' />
        </div>
        <div className='grid gap-5 grid-cols-3'>
          <div>
            <Translate className='text-sm-medium text-unselected' value='AccountName' />
            <div>{beneficiaryDetails.accountName}</div>
          </div>
          <div>
            <Translate className='text-sm-medium text-unselected' value='AccountNumber' />
            <div>{beneficiaryDetails.accountNumber}</div>
          </div>
          <div>
            <Translate className='text-sm-medium text-unselected' value='BeneficiaryBankName' />
            <div>{beneficiaryDetails.bankName}</div>
          </div>
          <div>
            <Translate className='text-sm-medium text-unselected' value='BankCode' />
            <div>{beneficiaryDetails.routingCode}</div>
          </div>
          {beneficiaryDetails.beneAddress?.city ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='BeneficiaryAddressCity'
              />
              <div>{beneficiaryDetails.beneAddress.city}</div>
            </div>
          ) : null}
          {beneficiaryDetails.beneAddress?.street1 ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='BeneficiaryAddressStreet'
              />
              <div>{beneficiaryDetails.beneAddress.street1}</div>
            </div>
          ) : null}
          {beneficiaryDetails.beneAddress?.state ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='BeneficiaryAddressRegion'
              />
              <div>{beneficiaryDetails.beneAddress.state}</div>
            </div>
          ) : null}
          {beneficiaryDetails.countryOfResidence && (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='BeneficiaryAddressCountry'
              />
              <div>
                <div className='flex gap-2 items-center'>
                  <img
                    alt={beneficiaryDetails.countryOfResidence ?? ''}
                    src={`/images/country_flags/${beneficiaryDetails.countryOfResidence?.toLocaleLowerCase()}.svg`}
                    className='w-[20px] h-[20px] rounded-full object-cover'
                  />
                  <span>{getCountryISO3(beneficiaryDetails.countryOfResidence)}</span>
                </div>
              </div>
            </div>
          )}
          {beneficiaryDetails.bankAddressId?.city ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='BeneficiaryBankAddressCity'
              />
              <div>{beneficiaryDetails.bankAddressId.city}</div>
            </div>
          ) : null}
          {beneficiaryDetails.bankAddressId?.street1 ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='BeneficiaryBankAddressStreet'
              />
              <div>{beneficiaryDetails.bankAddressId.street1}</div>
            </div>
          ) : null}
          {beneficiaryDetails.bankAddressId?.state ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='BeneficiaryAddressRegion'
              />
              <div>{beneficiaryDetails.bankAddressId.state}</div>
            </div>
          ) : null}
          {beneficiaryDetails.bankAddressId?.postCode ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='BeneficiaryBankAddressPostalCode'
              />
              <div>{beneficiaryDetails.bankAddressId.postCode}</div>
            </div>
          ) : null}
          {beneficiaryDetails.bankAddressId?.country ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='BeneficiaryBankAddressCountry'
              />
              <div>{beneficiaryDetails.bankAddressId.country}</div>
            </div>
          ) : null}
          {beneficiaryDetails.intermediaryBankCode ? (
            <div>
              <Translate className='text-sm-medium text-unselected' value='IntermediaryBankCode' />
              <div>{beneficiaryDetails.intermediaryBankCode}</div>
            </div>
          ) : null}
          {beneficiaryDetails.intermediaryBankName ? (
            <div>
              <Translate className='text-sm-medium text-unselected' value='IntermediaryBankName' />
              <div>{beneficiaryDetails.intermediaryBankName}</div>
            </div>
          ) : null}
          {beneficiaryDetails.intermediaryBankAddId?.city ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='IntermediaryBankAddressCity'
              />
              <div>{beneficiaryDetails.intermediaryBankAddId.city}</div>
            </div>
          ) : null}
          {beneficiaryDetails.intermediaryBankAddId?.street1 ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='IntermediaryBankAddressStreet'
              />
              <div>{beneficiaryDetails.intermediaryBankAddId.street1}</div>
            </div>
          ) : null}
          {beneficiaryDetails.intermediaryBankAddId?.state ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='IntermediaryBankAddressRegion'
              />
              <div>{beneficiaryDetails.intermediaryBankAddId.state}</div>
            </div>
          ) : null}
          {beneficiaryDetails.intermediaryBankAddId?.postCode ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='IntermediaryBankAddressPostalCode'
              />
              <div>{beneficiaryDetails.intermediaryBankAddId.postCode}</div>
            </div>
          ) : null}
          {beneficiaryDetails.intermediaryBankAddId?.country ? (
            <div>
              <Translate
                className='text-sm-medium text-unselected'
                value='IntermediaryBankAddressCountry'
              />
              <div>{beneficiaryDetails.intermediaryBankAddId.country}</div>
            </div>
          ) : null}
          <div>
            <Translate className='text-sm-medium text-unselected' value='paymentPurpose' />
            <div>{beneficiaryDetails.paymentPurpose}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
