import { User } from '@carbon/icons-react';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { Translate } from '../../i18n/translate';
import { enumToCamelCase, formatDateRelativeTime } from '../../utils/helper';
import { ApprovalHistoryService } from './approvalHistoryService';

const ApprovalHistory = ({
  showHistory,
  setShowHistory,
  recodrdId,
  feature,
}: {
  showHistory: boolean;
  setShowHistory: (_boolean: boolean) => void;
  recodrdId: string;
  feature: string;
}) => {
  const [history, setHistory] = useState<ApprovalHistory[]>([]);

  useEffect(() => {
    if (showHistory) {
      getHistoryMutation.mutate();
    }
  }, [showHistory]);

  const getHistoryMutation = useMutation({
    mutationFn: async () => {
      const response: ApprovalHistory[] | 'Error' = await ApprovalHistoryService.getHistory(
        feature,
        recodrdId,
      );
      if (response !== 'Error') {
        setHistory(response);
      }
    },
  });

  return (
    <Dialog
      draggable={false}
      className='w-[30%] min-w-[] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      header={
        <div>
          <Translate value='approval.history.title' />
        </div>
      }
      visible={showHistory}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0, minWidth: '400px' }}
      onHide={() => setShowHistory(false)}
      footer={
        <Button severity='secondary' onClick={() => setShowHistory(false)}>
          <Translate value='cancel' />
        </Button>
      }
    >
      {getHistoryMutation.isPending && (
        <div>
          <Translate value='loading' />
        </div>
      )}
      {!getHistoryMutation.isPending && history.length == 0 ? (
        <div>
          <Translate value='approval.history.noData' />
        </div>
      ) : (
        history.map((item, index) => (
          <div key={index} className='flex mb-6'>
            <div className='flex flex-col items-center mr-4 relative'>
              <div className='rounded-[200px] bg-avatar w-[48px] h-[48px] flex justify-center items-center'>
                <User size='24' className='mb-2' />
              </div>
              {index !== history.length - 1 && (
                <div className='w-px h-10 bg-gray-300 dotted-line'></div>
              )}
            </div>
            <div className='flex-grow'>
              <div className=''>
                <span className='font-bold'>{item.userName}</span> {/* Fetch the user's name */}
                <span className='text-sm text-gray-500 ml-5'>
                  {formatDateRelativeTime(item.createdAt)}
                </span>{' '}
              </div>
              <div className='mt-1'>
                <strong>{enumToCamelCase(item.userAction)}</strong>
                <p className='text-sm text-gray-600 mt-1'>{item.userComment}</p>
              </div>
            </div>
          </div>
        ))
      )}
    </Dialog>
  );
};

export default ApprovalHistory;
