import { Add } from '@carbon/icons-react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useRef } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CurrencySelect, DropdownInput, TextInput } from '../../../../components';
import { Translate, translateWithValues, TranslateWithValues } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer, sendToast } from '../../../../store/slices/main/mainSlice';
import { MarginType } from '../../../../types';
import { MARGIN_TYPE_OPTIONS } from '../Dashboard/formConstants';
import ManagePricingService from '../managePricingService';
import './../index.css';
import { useNavigate } from 'react-router';

const CreateMargin = ({
  visible,
  setCreateMarginToggle,
  editEntity,
  client,
  isViewMode = false,
}: {
  visible: boolean;
  editEntity?: Margin;
  client?: Client;
  isViewMode?: boolean;
  setCreateMarginToggle: (visible: boolean) => void;
}) => {
  const {
    watch,
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<CreateMarginFormData>({
    defaultValues: {
      managedMarginList: [
        {
          tierName: 'Tier 1',
          margin: '',
          minThreshold: '',
          maxThreshold: '',
        },
      ],
    },
  });
  const formRef = useRef(null);
  const currentCustomer = useSelector(selectCurrentCustomer);
  const marginType = watch('marginType');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (editEntity) {
      reset({
        ...editEntity,
        currencyList:
          marginType == MarginType.CURRENCY ? editEntity.currencyList[0] : editEntity.currencyList,
      });
    } else {
      reset({
        ...Object.fromEntries(Object.entries(getValues()).map(([key]) => [key, ''])),
        marginType: marginType,
        managedMarginList: [
          {
            tierName: 'Tier 1',
            margin: '',
            minThreshold: '',
            maxThreshold: '',
          },
        ],
      });
    }
  }, [marginType, editEntity]);

  const { fields, append } = useFieldArray({
    control,
    name: 'managedMarginList',
  });

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const hasTiersOverlapping = (tiers: Tier[]) => {
    const minMaxIntervals = tiers
      .map((tier) => [Number(tier.minThreshold), Number(tier.maxThreshold)])
      .sort((a, b) => a[0] - b[0]);
    for (let i = 1; i < minMaxIntervals.length; i++) {
      if (minMaxIntervals[i][0] <= minMaxIntervals[i - 1][1]) {
        return true;
      }
    }
    return false;
  };

  const onSave = async (data: CreateMarginFormData) => {
    if (data.marginType === MarginType.TIERED && hasTiersOverlapping(data.managedMarginList)) {
      showToast('error', 'tiers_validation', 'cannot_have_overlapping_tiers');
    } else {
      const updateData = {
        ...data,
        currencyList: marginType == MarginType.CURRENCY ? [data.currencyList] : data.currencyList,
      };
      if (currentCustomer?.id) {
        if (!editEntity) {
          const response = await ManagePricingService.createMargin(
            client?.id ? client.id : currentCustomer.id,
            updateData,
          );
          handleResponse(response, data.marginType);
        } else {
          const response = await ManagePricingService.updateMargin(editEntity.id, updateData);
          handleResponse(response, data.marginType);
        }
      }
    }
  };

  const handleResponse = (response: AxiosResponse, marginType: string) => {
    const { data, status } = response;
    if (status !== 200) {
      showToast(
        'error',
        'error',
        editEntity ? 'margin.updation.error.toast.desc' : 'margin.creation.error.toast.desc',
      );
      return;
    } else {
      const { result } = data;
      if (result.success) {
        showToast(
          'success',
          'success',
          editEntity ? 'margin.update.toast' : 'margin.success.toast',
        );
        setCreateMarginToggle(false);
        navigate('/configuration/managed-pricing?tab=MARGINS');
      } else {
        const { errors } = result;
        showToast(
          'error',
          'error',
          (errors[0] && getError(errors[0].errorTextCode, marginType)) ?? '',
          {
            ...errors[0],
          },
        );
        return;
      }
    }
  };

  const getError = (error: string, marginType: string) => {
    return error + '_' + marginType;
  };

  const getMarginHeader = () => {
    const baseTitle = client ? 'ForClient' : '';

    if (isViewMode) {
      return `viewMarginTitle${baseTitle}`;
    } else if (editEntity) {
      return `editMarginTitle${baseTitle}`;
    } else {
      return `createMarginTitle${baseTitle}`;
    }
  };

  const headerElement = () => (
    <div className='flex justify-between items-center w-full'>
      <div className='flex flex-col gap-1'>
        <div className='text-lg font-semibold text-neutral-1'>
          <TranslateWithValues
            value={getMarginHeader()}
            params={client ? { clientName: client?.customerShortName } : {}}
          />
        </div>
        <div className='text-sm font-regular text-neutral-3'>
          <Translate value='createMarginDescription' className='!text-sm-regular' />
        </div>
      </div>
    </div>
  );
  const footerContent = (
    <div className='flex justify-end mt-5 gap-2'>
      <Button severity='secondary' onClick={() => setCreateMarginToggle(false)}>
        <Translate value='cancel' />
      </Button>
      {!isViewMode && (
        <Button
          severity='info'
          onClick={() => {
            if (formRef.current) {
              (formRef.current as HTMLFormElement).dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true }),
              ); // Manually trigger form submit
            }
          }}
        >
          <Translate value='save' />
        </Button>
      )}
    </div>
  );

  const nonNegativeDecimalValidation = (value: string) => {
    const val = Number(value);
    if (isNaN(val)) {
      return mustBeNonNegativeTooltipMesssage;
    }
    return val >= 0 || mustBeNonNegativeTooltipMesssage;
  };

  const isGreaterValidation = (index: number, value: string) => {
    const minAmount = getValues('managedMarginList')[index].minThreshold;
    return minAmount
      ? parseFloat(value) > parseFloat(minAmount) || maxAmountGreaterTooltipMessage
      : true;
  };

  const maxAmountGreaterTooltipMessage = translateWithValues('max_amount_greater_than_min_amount');
  const mustBeNonNegativeTooltipMesssage = translateWithValues('must_be_non_negative_number');
  return (
    <Dialog
      draggable={false}
      className='w-[30%] min-w-[] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      header={headerElement}
      visible={visible}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0, minWidth: '400px' }}
      onHide={() => setCreateMarginToggle(false)}
      footer={footerContent}
    >
      {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(onSave)} ref={formRef}>
          <DropdownInput
            label='marginType'
            placeholder='marginType'
            name='marginType'
            disabled={!!editEntity || isViewMode}
            isRequired
            control={control}
            options={MARGIN_TYPE_OPTIONS.filter(
              (option) => editEntity || option.value !== MarginType.BASE,
            )}
            rules={{ required: true }}
            error={errors['marginType']}
          />

          <TextInput
            className='mt-[32px]'
            label='description'
            disabled={isViewMode}
            placeholder='description'
            isRequired
            formRegister={register('description', { required: true })}
            error={errors['description']}
          />

          {marginType !== MarginType.CURRENCY && (
            <CurrencySelect
              currencySource='buy'
              control={control}
              className='mt-[32px] w-full'
              disabled={isViewMode}
              name='currencyList'
              label='ccy'
              placeholder='ccy'
              rules={{ required: true }}
              error={errors['currencyList']}
              isRequired
              isMulti={true}
              includeAll={marginType === MarginType.TIERED}
            />
          )}

          {marginType === MarginType.CURRENCY && (
            <CurrencySelect
              currencySource='buy'
              control={control}
              className='mt-[32px] w-full'
              name='currencyList'
              label='ccy'
              placeholder='ccy'
              rules={{ required: true }}
              error={errors['currencyList']}
              isRequired
              disabled={isViewMode}
            />
          )}

          {marginType && marginType !== MarginType.TIERED && (
            <>
              <TextInput
                className='mt-[32px]'
                placeholder={translateWithValues('margin_percentage')}
                label='margin'
                isRequired
                formRegister={register('managedMarginList.0.margin', {
                  required: true,
                  max: {
                    value: 100,
                    message: `${translateWithValues('margin')} ${translateWithValues('cannot_exceed_hundred')}`,
                  },
                  validate: nonNegativeDecimalValidation,
                })}
                error={errors.managedMarginList?.[0]?.margin}
                disabled={isViewMode}
              />
              <div className='mt-[32px]'>
                <label>
                  <Translate value='amount' />
                </label>
                <div className='flex'>
                  <TextInput
                    formRegister={register('managedMarginList.0.minThreshold', {
                      required: marginType !== MarginType.GROUP,
                      validate: nonNegativeDecimalValidation,
                    })}
                    error={errors.managedMarginList?.[0]?.minThreshold}
                    errorLabel='min_amount'
                    placeholder={translateWithValues('min_amount')}
                    disabled={isViewMode}
                  />
                  <div className='ml-2 mr-2 pt-2'> - </div>

                  <TextInput
                    formRegister={register('managedMarginList.0.maxThreshold', {
                      required: marginType !== MarginType.GROUP,
                      validate: {
                        isGreater: (value) => isGreaterValidation(0, value),
                        isWholeNumber: nonNegativeDecimalValidation,
                      },
                    })}
                    error={errors.managedMarginList?.[0]?.maxThreshold}
                    errorLabel='max_amount'
                    placeholder={translateWithValues('max_amount')}
                    disabled={isViewMode}
                  />
                </div>
              </div>
            </>
          )}

          {marginType === MarginType.TIERED && (
            <div className='flex flex-col gap-6 mt-8'>
              {fields.map((tier, index) => (
                <div key={index} className='grid grid-cols-4 gap-1'>
                  <TextInput
                    formRegister={register(`managedMarginList.${index}.tierName`)}
                    label={`Tier ${index + 1}`}
                    placeholder='Tier name'
                    disabled={isViewMode}
                  />

                  <TextInput
                    label=''
                    placeholder='Margin'
                    isRequired
                    className='mt-7'
                    error={errors.managedMarginList?.[index]?.margin}
                    errorLabel='margin'
                    formRegister={register(`managedMarginList.${index}.margin`, {
                      required: true,
                      max: {
                        value: 100,
                        message: `${translateWithValues('margin')} ${translateWithValues('cannot_exceed_hundred')}`,
                      },
                      validate: nonNegativeDecimalValidation,
                    })}
                    disabled={isViewMode}
                  />

                  <TextInput
                    placeholder='min_amount'
                    className='mt-7'
                    error={errors.managedMarginList?.[index]?.minThreshold}
                    errorLabel='min_amount'
                    formRegister={register(`managedMarginList.${index}.minThreshold`, {
                      required: true,
                      validate: nonNegativeDecimalValidation,
                    })}
                    disabled={isViewMode}
                  />

                  <TextInput
                    placeholder='max_amount'
                    className='mt-7'
                    error={errors.managedMarginList?.[index]?.maxThreshold}
                    errorLabel='max_amount'
                    formRegister={register(`managedMarginList.${index}.maxThreshold`, {
                      required: true,
                      validate: {
                        wholeNumber: nonNegativeDecimalValidation,
                        isGreater: (value) => isGreaterValidation(index, value),
                      },
                    })}
                    disabled={isViewMode}
                  />
                </div>
              ))}
              {!isViewMode && (
                <a
                  onClick={() => {
                    append({
                      tierName: `Tier ${fields.length + 1}`,
                      margin: '',
                      minThreshold: '',
                      maxThreshold: '',
                    });
                  }}
                  className='cursor-pointer text-primary text-sm-semibold'
                >
                  <Add className='mr-1 inline' /> Add Tier
                </a>
              )}
            </div>
          )}
        </form>
      }
    </Dialog>
  );
};

export default CreateMargin;
