import { Dialog } from 'primereact/dialog';

const InformationModal: React.FC<{
  visible: boolean;
  onConfirm: (show: boolean) => void;
  className?: string;
  headerIcon?: React.ReactNode;
  ContentComponent: React.FC<{ onClose: () => void }>;
}> = ({ visible, onConfirm, className, ContentComponent }) => {
  return (
    <Dialog
      draggable={false}
      className={`rounded-[12px] ${className} confirm-dialog`}
      visible={visible}
      header={<></>}
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      onHide={() => onConfirm(!visible)}
    >
      <ContentComponent onClose={() => onConfirm(!visible)} />
    </Dialog>
  );
};

export default InformationModal;
