import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ApprovalConfirm, ApprovalHistory, RadioCard, TextInput } from '../../../../../components';
import { Translate } from '../../../../../i18n/translate';
import { PageHeadContext } from '../../../../../providers/PageHead';
import { selectLoading } from '../../../../../store/slices/main/mainSlice';
import { AddOrEditOrView, Status } from '../../../../../types';
import ApprovalsSerive from '../../approvalsService';

function UpdateApprovalStatus({ mode }: { mode: AddOrEditOrView }) {
  const [searchParams] = useSearchParams();
  const isLoading = useSelector(selectLoading);
  const modelId = searchParams.get('modelId');
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [apporveOrject, setApporveOrject] = useState<'approve' | 'reject'>();
  const [showHistory, setShowHistory] = useState(false);
  const [canBeApproved, setCanBeApproved] = useState(false);
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;

  useEffect(() => {
    setPageHeadData({
      title: 'approvals',
      description: 'approvals.view.description',
      button: (
        <>
          {mode && [AddOrEditOrView.Approve, AddOrEditOrView.View].includes(mode) && (
            <Button
              severity='secondary'
              className='h-[45px] mr-[50px]'
              onClick={() => {
                setShowHistory(true);
              }}
            >
              <Translate value='approval.history' />
            </Button>
          )}
        </>
      ),
    });
    return () => {
      setPageHeadData({ title: '', description: '' });
    };
  }, []);

  useEffect(() => {
    if (modelId && mode === AddOrEditOrView.Approve) {
      ApprovalsSerive.getApprovalItemDetails(Number(modelId)).then((response) => {
        const {
          data: {
            data: { data: approval },
          },
        } = response;
        setCanBeApproved(approval.canBeApprovedByCurrentUser ?? false);
      });
    }
  }, [modelId]);

  const approvalDetailsQuery = useQuery({
    queryKey: ['approval_structure_by_modelId', modelId],
    queryFn: async () => {
      const data = await ApprovalsSerive.getApprovalStructureByModelId(Number(modelId));
      return data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (!modelId) {
    return (
      <h2 className='text-center'>
        <Translate value='modelId_not_found' className='text-lg-bold' />
      </h2>
    );
  }

  if (approvalDetailsQuery.isLoading) {
    return <div className='text-center'>Loading...</div>;
  }

  if (!approvalDetailsQuery.data) {
    return (
      <div className='text-center'>
        <Translate value='approvals.structure_not_found' />
      </div>
    );
  }

  return (
    <>
      <ApprovalHistory
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        recodrdId={modelId ?? ''}
        feature='MANAGE_APPROVAL_WORKFLOW'
      />

      <ApprovalConfirm
        showApproveConfirm={showApproveConfirm}
        setShowApproveConfirm={(v) => {
          setShowApproveConfirm(v);
          approvalDetailsQuery.refetch();
        }}
        apporveOrject={apporveOrject}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        approveFn={ApprovalsSerive.updateApprovalStructureStatus}
        id={approvalDetailsQuery.data?.id}
        type='approval'
      />
      <div className='grid gap-4'>
        <div className='card flex justify-between items-start lg:px-14 lg:py-6'>
          <div className='grid gap-6'>
            <div className='grid gap-1'>
              <Translate
                value='approvals.update_status.heading'
                className='text-neutral-1 text-lg-semibold'
              />
              <Translate
                value='approvals.update_status.description'
                className='text-neutral-3 text-sm-regular'
              />
            </div>
            <TextInput
              label='Structure Name'
              className='max-w-[400px]'
              value={approvalDetailsQuery.data.modelName}
              disabled
            />
          </div>
          {[Status.PENDING_APPROVAL, Status.PENDING_CREATION].includes(
            approvalDetailsQuery.data.approvalStatus,
          ) &&
            canBeApproved && (
              <div>
                <Button
                  disabled={isLoading}
                  severity='secondary'
                  className='w-32 inline-flex justify-center gap-2'
                  onClick={() => {
                    setApporveOrject('reject');
                    setShowApproveConfirm(true);
                  }}
                >
                  <Translate value='reject' />
                </Button>

                <Button
                  disabled={isLoading}
                  severity='info'
                  className='ml-2 w-32 inline-flex justify-center gap-2'
                  onClick={() => {
                    setApporveOrject('approve');
                    setShowApproveConfirm(true);
                  }}
                >
                  <Translate value='approve' />
                </Button>
              </div>
            )}
        </div>
        <div className='card lg:px-14 lg:py-6'>
          <Translate
            value='approvals.update_status.approval_structure_type'
            className='text-neutral-1 text-lg-semibold'
          />
          <div className='grid grid-cols-2 lg:grid-cols-4 gap-5 mt-6'>
            <RadioCard
              label='create_approval.simple'
              subLabel='create_approval.simple.description'
              value='SIMPLE'
              name='appStructure'
              checked={approvalDetailsQuery.data.appStructure === 'SIMPLE'}
              disabled
            />
            <RadioCard
              label='create_approval.sequential'
              subLabel='create_approval.sequential.description'
              value='SEQUENTIAL'
              name='appStructure'
              checked={approvalDetailsQuery.data.appStructure === 'SEQUENTIAL'}
              disabled
            />
            {approvalDetailsQuery.data.feature === 'PAYMENT' && (
              <RadioCard
                label='create_approval.tiered'
                subLabel='create_approval.tiered.description'
                value='TIERED'
                name='appStructure'
                checked={approvalDetailsQuery.data.appStructure === 'TIERED'}
                disabled
              />
            )}
            <RadioCard
              label='create_approval.group'
              subLabel='create_approval.group.description'
              value='GROUPED'
              name='appStructure'
              checked={approvalDetailsQuery.data.appStructure === 'GROUPED'}
              disabled
            />
          </div>
        </div>
        <div className='card p-0'>
          <div className='px-4 lg:px-14 py-10 border-b border-neutral-surface-subtle'>
            <Translate
              value='approvals.update_status.assign_approvers'
              className='text-neutral-1 text-lg-semibold'
            />
            <div className='grid grid-cols-2 lg:grid-cols-4 gap-5 pt-6'>
              <RadioCard
                label='create_approval.role_based'
                subLabel='create_approval.role_based.description'
                value='ROLE'
                name='approverCategory'
                checked={approvalDetailsQuery.data.approverCategory === 'ROLE'}
                disabled
              />
              <RadioCard
                label='create_approval.user_based'
                subLabel='create_approval.user_based.description'
                value='USER'
                name='approverCategory'
                checked={approvalDetailsQuery.data.approverCategory === 'USER'}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateApprovalStatus;
