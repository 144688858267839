const NextButton = () => (
  <svg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_d_6462_1483)'>
      <rect x='3' y='2' width='40' height='40' rx='8' fill='white' />
      <rect x='3.5' y='2.5' width='39' height='39' rx='7.5' stroke='#C9D2DE' />
      <path
        d='M24.25 15.75L23.3562 16.6206L28.0938 21.375H15.5V22.625H28.0938L23.3562 27.3581L24.25 28.25L30.5 22L24.25 15.75Z'
        fill='#0F172A'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_6462_1483'
        x='0'
        y='0'
        width='46'
        height='46'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='1'
          operator='dilate'
          in='SourceAlpha'
          result='effect1_dropShadow_6462_1483'
        />
        <feOffset dy='1' />
        <feGaussianBlur stdDeviation='1' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.701961 0 0 0 0 0.752941 0 0 0 0 0.8 0 0 0 0.16 0'
        />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_6462_1483' />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_6462_1483'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);

export default NextButton;
