import { Outlet } from 'react-router';
import { PageHeadProvider } from '../../providers/PageHead';
import { PageHead } from '../../components';

const NotificationOutlet = () => {
  return (
    <>
      <div className='pl-[48px] py-[16px] bg-white border-b border-neutral-border'>
        <PageHead title='notifications.title' description='notifications.subtitle' />
      </div>
      <PageHeadProvider>
        <div>
          <Outlet />
        </div>
      </PageHeadProvider>
    </>
  );
};
export default NotificationOutlet;
